import * as React from 'react';
import {useMemo} from 'react';
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Box} from "@mui/material";
import {NavigateFunction, useLocation, useNavigate} from "react-router";
import {useAuth} from "../auth/auth-provider";

export function GoToPurchaseScreen(navigate: NavigateFunction, location: any) {
    navigate("/account?tab=subscription", {state: {"pathname": location.pathname}});
}

export function PurchaseButton() {
    const {authContext} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const show = useMemo(() => {
        const isTrial = authContext?.account?.features.find(f => f === "trial") !== undefined;
        const isTrialExpired = authContext?.account?.features.length === 0;

        return authContext?.account?.isOwner && (isTrial || isTrialExpired);
    }, [authContext]);

    const handlePurchaseClicked = () => {
        GoToPurchaseScreen(navigate, location)
    }

    return (
        <Box>
            {show &&
                <Button sx={{color: "primary.contrastText", mr: 4}}
                        color="alternate"
                        variant="contained"
                        startIcon={<ShoppingCartIcon/>}
                        onClick={handlePurchaseClicked}>
                    Buy TeamCal
                </Button>
            }
        </Box>
    );
}
