import * as React from "react";
import {createContext, ReactElement, useContext} from "react";
import {localeEn, MbscLocale} from "@mobiscroll/react";
import {useAuth} from "../../auth/auth-provider";
import {components} from "../../clients/teamcal-api-schema";
import {useSchedule} from "./schedule-provider";

const shortDayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

type LuxonLocale = {
    timeFormat: string;
    dateFormat: string;
    dateTimeFormat: string;
}

export type LocaleCtx = {
    uiTimezone: string;
    showWeekNumbers: boolean;
    mbscLocale: MbscLocale,
    luxonLocale: LuxonLocale,
}

const LocaleContext = createContext<LocaleCtx | undefined>(undefined);

export function LocaleProvider({children}: { children: ReactElement }) {
    const {authContext} = useAuth();
    const schedule = useSchedule();
    const data = buildLocalFromSettings(authContext!.account!.userSettings);

    if (schedule.data.timezone) {
        data.uiTimezone = schedule.data.timezone;
    }

    return (
        <LocaleContext.Provider value={data}>{children}</LocaleContext.Provider>
    );
}

export function useLocale(): LocaleCtx {
    const ctx = useContext(LocaleContext);
    if (!ctx) {
        throw new Error("useSchedule can only be used in a ScheduleProvider tree");
    }

    return ctx;
}

function buildLocalFromSettings(userSettings: components["schemas"]["UserSettings"]): LocaleCtx {
    const mbscLocale = {...localeEn};
    const luxonLocale = {
        timeFormat: userSettings.time24h ? "HH:mm" : "hh:mm a",
        dateFormat: "",
        dateTimeFormat: "",
    };

    //mbscLocale.dayNamesShort = shortDayNames;
    mbscLocale.timeFormat = userSettings.time24h ? "HH:mm" : "hh:mm A";
    mbscLocale.firstDay = userSettings.weekStart;

    switch (userSettings.dateOrder) {
        case "MDY": {
            mbscLocale.dateFormat = "MM/DD/YYYY";
            mbscLocale.dateFormatFull = "DDDD, MMMM D, YYYY";  // Monday, October 14, 2024
            mbscLocale.dateFormatLong = "DDD MMM D YYYY";  // Mon Oct 14 2024
            luxonLocale.dateFormat = "LLL d, yyyy";  // Oct 14 2024
            luxonLocale.dateTimeFormat = "LLL d, yyyy";  // Oct 14 2024
            break;
        }
        case "DMY": {
            mbscLocale.dateFormat = "DD/MM/YYYY";
            mbscLocale.dateFormatFull = "DDDD, D MMMM, YYYY";  // Monday, 14 October, 2024
            mbscLocale.dateFormatLong = "DDD D MMM YYYY";  // Mon 14 Oct 2024
            luxonLocale.dateFormat = "d LLL, yyyy";  // 14 Oct, 2024
            break;
        }
        case "YMD": {
            mbscLocale.dateFormat = "YYYY-MM-DD";
            mbscLocale.dateFormatFull = "DDDD, YYYY, MMMM D";  // Monday, 2024, October 14
            mbscLocale.dateFormatLong = "DDD YYYY MMM D ";  // Mon 2024 Oct 14
            luxonLocale.dateFormat = "yyyy, LLL d";  // 2024, Oct 14
            break;
        }
    }

    luxonLocale.dateTimeFormat = `${luxonLocale.dateFormat} ${luxonLocale.timeFormat}`;

    return {
        uiTimezone: userSettings.timezone!,
        showWeekNumbers: userSettings.showWeeks!,
        mbscLocale: mbscLocale,
        luxonLocale: luxonLocale,
    };
}