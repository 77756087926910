import * as React from "react";
import {Box, Stack, Typography} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export function RenderEvent({eventSize, title, location, backgroundColor, declinedEvent, workLocation}: {
    eventSize: string,
    title?: string,
    location?: string,
    backgroundColor?: string,
    declinedEvent?: boolean,
    workLocation?: boolean,
}) {
    const lineHeight = 1.1;
    const lineHeightPx = 13; // Pixels
    const maxLines = eventSize === "C" ? 1 : eventSize === "B" ? 3 : 2;

    if (workLocation) {
        return (
            <Box sx={{
                backgroundColor: backgroundColor,
                color: "white",
                borderRadius: "8px",
                pl: 0.5,
                pr: 0.5,
                ml: 0.2,
                mr: 0.2,
            }}>
                <Stack direction="row" spacing={0.25}
                       sx={{
                           height: 16,
                           overflow: "hidden",
                           overflowWrap: "break-word",
                           display: "flex",
                           alignItems: "center"
                       }}>
                    <LocationOnIcon sx={{fontSize: 12}}/>
                    <Typography
                        variant="caption"
                        sx={{
                            lineHeight: lineHeight,
                            fontWeight: 500,
                        }}>
                        {title}
                    </Typography>
                </Stack>
            </Box>
        );
    }

    return (
        <Box sx={{
            backgroundColor: backgroundColor,
            color: "white",
            borderRadius: "4px",
            p: 0.5,
            ml: 0.2,
            mr: 0.2,
        }}>
            <Stack direction="column"
                   sx={{height: lineHeightPx * maxLines, overflow: "hidden", overflowWrap: "break-word"}}>
                <Typography
                    variant="caption"
                    sx={{
                        lineHeight: lineHeight,
                        fontWeight: 500,
                        textDecoration: declinedEvent ? "line-through" : undefined,
                    }}>
                    {title}
                </Typography>
                {location &&
                    <Typography
                        variant="caption"
                        sx={{
                            lineHeight: lineHeight,
                        }}>
                        {location}
                    </Typography>
                }
            </Stack>
        </Box>
    );
}

export const MemoRenderEvent = React.memo(RenderEvent);
