import * as Sentry from "@sentry/react";
import {DateTime, Duration} from "luxon";

const timezones: { [zone: string]: string } = {};
try {
    for (const zone of (Intl as any).supportedValuesOf('timeZone')) {
        timezones[zone] = `GMT${DateTime.local({zone}).toFormat('ZZ')}`;
    }
} catch (e) {
    Sentry.captureException(e);
}

export const Timezones = Object.entries(timezones).sort((z1, z2) => {
    const [, offset1] = z1;
    const [, offset2] = z2;
    return offset1.localeCompare(offset2);
});

export const WeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export function asLuxonDate(val: string | object | Date | undefined): DateTime {
    if (val instanceof Date) {
        return DateTime.fromJSDate(val);
    } else if (typeof val === "string") {
        if (val.includes("T")) {
            return DateTime.fromISO(val as string);
        } else {
            return DateTime.fromISO(val as string, {zone: "UTC", setZone: true});
        }
    } else {
        throw new Error(`Invalid date type: ${val}`);
    }
}

export function compareStringDate(d1: string | object | Date | undefined, d2: string | object | Date | undefined): number {
    return compareLuxonDate(asLuxonDate(d1), asLuxonDate(d2))
}

export function compareLuxonDate(d1: DateTime, d2: DateTime): number {
    return d1.valueOf() - d2.valueOf();
}

export function formatWorkDayHour(hours: number): string {
    return Duration.fromObject({minutes: hours}).toFormat("hh:mm")
}

export function based0Weekday(date: DateTime): number {
    return (date.weekday + 7) % 7;
}

export function isInDayRange(day: number, start: number, end: number): boolean {
    if (start <= end) {
        return day >= start && day <= end;
    } else {
        return day >= start || day <= end;
    }
}

export function nextDaySkipWeekend(date: DateTime, workWeekStart: number, workWeekEnd: number): DateTime {
    const nextDay = date.plus({"days": 1})
    if (!isInDayRange(based0Weekday(nextDay), workWeekStart, workWeekEnd)) {
        return nextDaySkipWeekend(nextDay, workWeekStart, workWeekEnd);
    } else {
        return nextDay;
    }
}

export function previousDaySkipWeekend(date: DateTime, workWeekStart: number, workWeekEnd: number): DateTime {
    const prevDay = date.minus({"days": 1})
    if (!isInDayRange(based0Weekday(prevDay), workWeekStart, workWeekEnd)) {
        return previousDaySkipWeekend(prevDay, workWeekStart, workWeekEnd);
    } else {
        return prevDay;
    }
}