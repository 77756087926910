import * as React from "react";
import {Box, Menu, MenuItem, Tooltip, ListItemText, ListItemIcon, IconButton} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ViewModeEnum} from "../../../../clients/teamcal-api";
import {useSchedule} from "../../schedule-provider";

type EventSize = {
    key: ViewModeEnum;
    label: string;
}

export const EventSizes: EventSize[] = [
    {key: "C", label: "Compact"},
    {key: "N", label: "Normal"},
    {key: "B", label: "Large"},
];

export function ViewSettingsSelector() {
    const schedule = useSchedule();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowWorkHoursChange = (value: boolean) => {
        handleClose();
        schedule.applyChanges([{prop: "showWorkHours", value: value}]);
    }

    const handleShowWeekendsChange = (value: boolean) => {
        handleClose();
        schedule.applyChanges([{prop: "showWeekends", value: value}]);
    }

    const handleEventSizeChange = (size: string) => {
        handleClose();
        schedule.applyChanges([{prop: "viewMode", value: size}]);
    }

    return (
        <Box>
            <Tooltip title="More view settings">
                <IconButton color="secondary" onClick={handleClick}>
                    <MoreVertIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {handleShowWorkHoursChange(!schedule.data.showWorkHours)}}>
                    {schedule.data.showWorkHours &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={!schedule.data.showWorkHours}>Work hours only</ListItemText>
                </MenuItem>
                <MenuItem divider={true} onClick={() => handleShowWeekendsChange(!schedule.data.showWeekends)}>
                    {schedule.data.showWeekends &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={!schedule.data.showWeekends}>Show weekends</ListItemText>
                </MenuItem>
                {EventSizes.map(size => {
                    return (
                        <MenuItem key={size.key} onClick={() => {
                            handleEventSizeChange(size.key);
                        }}>
                            {schedule.data.viewMode === size.key &&
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                            }
                            <ListItemText inset={schedule.data.viewMode !== size.key}>{size.label}</ListItemText>
                        </MenuItem>
                    );
                })
                }
            </Menu>
        </Box>
    );
}
