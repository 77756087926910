import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import * as React from "react";
import {useSchedule} from "../schedule/schedule-provider";
import {EditBox} from "../schedule/utils/edit-box";

export function SchedulingAppBar() {
    const schedule = useSchedule();

    return (
        <AppBar position="static" className="tc-hidden-print">
            <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                <Typography variant="h6">TeamCal</Typography>
                {schedule && (
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {schedule.data.name && <Typography variant="h6">:</Typography>}
                        <EditBox
                            value={schedule.data.name}
                            disabled={true}
                            onChange={() => {}}
                        />
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}
