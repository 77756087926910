import * as React from "react";
import {useRef} from "react";
import {DialogProps, useNotifications} from "@toolpad/core";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {DateTime} from "luxon";

export type InviteUserDialogPayload = {
    expiresOn: DateTime;
    secret: string;
}

export function InviteUserDialog({payload, open, onClose}: DialogProps<InviteUserDialogPayload>) {
    const notifications = useNotifications();
    const tokenInputRef = useRef<HTMLInputElement>(null);

    const handleCancel = async () => {
        await onClose(undefined);
    };

    const buildInviteLink = () => {
        return `${window.location.origin}/join/${payload.secret}`;
    }

    const selectLinkText = () => {
        if (tokenInputRef.current) {
            tokenInputRef.current!.select();
        }
    }

    const handleCopyToClipboard = async () => {
        const link = buildInviteLink();
        if (link) {
            selectLinkText();
            await navigator.clipboard.writeText(link);
            notifications.show("Copied", {severity: 'success', autoHideDuration: 3000});
        }
    }

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Invite user
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box sx={{pt: 2, pb: 2}}>
                        <Typography variant="body1">
                            Send the following link to the user you plan to invite.
                            This invitation expires  {payload.expiresOn.toRelative({padding: 3600 * 1000, style: "narrow"})} and
                            can only be used once.
                        </Typography>
                    </Box>
                    <Box sx={{pt: 2, pb: 2}}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                hiddenLabel
                                variant="outlined"
                                autoComplete="off"
                                fullWidth={true}
                                size="small"
                                value={buildInviteLink()}
                                onClick={selectLinkText}
                                inputRef={tokenInputRef}
                                slotProps={{
                                    input: {
                                        readOnly: true
                                    }
                                }}
                            ></TextField>
                            <Tooltip title="Copy link">
                                <IconButton onClick={handleCopyToClipboard}>
                                    <ContentCopyIcon/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleCancel} sx={{ml: 1}}>Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
