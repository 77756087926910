import * as React from "react";
import {useAuth} from "../../auth/auth-provider";
import {Link, Navigate, useLocation, useSearchParams} from "react-router";
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Link as MuiLink,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BusinessIcon from "@mui/icons-material/Business";
import {getCookie} from "../../utils/cookie";

export function Signup() {
    const {authContext} = useAuth()
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const refSource = searchParams.get("ref") || "";
    const extInstalled = searchParams.get("ext") === "installed";
    const crsfToken = getCookie("csrftoken");
    const pathname = location.state?.pathname;

    if (authContext && authContext.isAuthenticated)
        return <Navigate to="/"/>;
    else {
        return (
            <Box>
                <AppBar position="static">
                    <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                        <MuiLink sx={{color: "primary.contrastText"}} variant="h6"
                                 href="https://www.teamcalapp.com">TeamCal</MuiLink>
                        <span style={{flexGrow: 1}}></span>
                        <Button sx={{color: "primary.contrastText"}} component={Link} to="/login" state={{"pathname": pathname}}>Login</Button>
                    </Toolbar>
                </AppBar>
                <Box style={{background: "#b3e5fc", padding: "56px 16px", textAlign: "center"}}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h4">
                            {extInstalled ? "Thank you for installing TeamCal" : "Sign up for TeamCal"}
                        </Typography>
                        <Typography variant="subtitle1">
                            Google Calendar’s missing schedule view
                        </Typography>
                    </Stack>
                </Box>
                <Container maxWidth="xs">
                    <form id="signupForm" name="signupForm" autoComplete="off" method="post" action="oauth/google">
                        <input name="refSource" type="hidden" value={refSource} />
                        <input name="csrfmiddlewaretoken" type="hidden" value={crsfToken} />
                        <Stack direction="column" spacing={3} sx={{mt: 10}}>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" display="flex" alignItems="center" spacing={2}>
                                    <PersonOutlineIcon/>
                                    <TextField
                                        required
                                        label="Full name"
                                        size="small"
                                        fullWidth={true}
                                        variant="outlined"/>
                                </Stack>
                                <Typography sx={{pl: 5}} variant="caption">e.g. Jane Smith</Typography>
                            </Stack>
                            <Stack direction="row" display="flex" alignItems="center" spacing={2}>
                                <BusinessIcon/>
                                <TextField
                                    label="Company name"
                                    size="small"
                                    fullWidth={true}
                                    variant="outlined"/>
                            </Stack>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox required/>} label={
                                    <Typography component={"span"}>
                                        I agree to <MuiLink href="https://www.teamcalapp.com/terms/" target="_blank">Terms
                                        of Service</MuiLink> and to the <MuiLink
                                        href="https://www.teamcalapp.com/privacy/" target="_blank">Privacy
                                        Policy</MuiLink>.
                                    </Typography>
                                }/>
                            </FormGroup>
                            <Button variant="contained" color="primary" size="large" type="submit">
                                Get Started
                            </Button>
                        </Stack>
                    </form>
                </Container>
                <Box style={{paddingTop: "48px", textAlign: "center"}}>
                    ⭐ Our customers use TeamCal to schedule thousands of people ⭐
                </Box>
            </Box>
        );
    }
}
