import * as React from "react";
import {ReactElement, useCallback, useEffect, useState} from "react";
import {ScheduleChange, ScheduleContext} from "../schedule/schedule-provider";
import {Schedule, SharedViewInfo} from "../../clients/teamcal-api";

function buildScheduleFromSharedView(data: SharedViewInfo): Schedule {
    return {
        id: data.id,
        name: data.name,
        viewScale: "D",
        viewZoom: "hour",
        viewTimeframe: "D1",
        viewMode: "N",
        timezone: data.timezone,
        moveAction: "M",
        showWeekends: true,
        workWeekStart: data.workWeekStart,
        workWeekEnd: data.workWeekEnd,
        workDayStart: data.workDayStart,
        workDayEnd: data.workDayEnd,
        rows: data.rows.map(r => ({...r, extId: ""})),
        outOfOfficeSettings: null,
        allDaySettings: null,
        showWorkHours: true,
        showAllDayOnly: false,
    }
}

export function SharedScheduleProvider({sharedData, children}: { sharedData: SharedViewInfo, children: ReactElement }) {
    const [data, setData] = useState<Schedule>(() => buildScheduleFromSharedView(sharedData));

    const handleScheduleChanges = useCallback(async (changes: ScheduleChange[]) => {
        const mergedChanges = Object.assign({}, ...changes.map(({prop, value}) => ({[prop]: value})));

        setData(schedule => ({
            ...schedule!,
            ...mergedChanges,
        }));
    }, []);

    useEffect(() => {
        document.title = data?.name ? data?.name : "TeamCal App";
    }, [data?.name]);

    return (
        <ScheduleContext.Provider value={{data, applyChanges: handleScheduleChanges}}>
            {children}
        </ScheduleContext.Provider>
    );
}
