import * as React from "react";
import {useState} from "react";
import {Box, Chip, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {useNotifications} from '@toolpad/core';
import {MbscScheduleEvent} from "../../utils/utils";
import {SetEventProperty} from "../edit-event-dialog";
import {ScheduleEventAttendee} from "../../../../clients/teamcal-api";
import {CalendarEmailAutocomplete} from "./calendar-email-autocomplete";
import Button from "@mui/material/Button";


export function GuestsEventEdit({event, setEventProperty}: {
    event: MbscScheduleEvent,
    setEventProperty: SetEventProperty
}) {
    const notifications = useNotifications();
    const [emailInputValue, setEmailInputValue] = useState<string>("");

    const removeAttendee = (email: string) => {
        if (!event.attendees) {
            return;
        }

        const newAttendeeList = event.attendees.filter(a => {
            return a.email !== email;
        });

        setEventProperty("attendees", newAttendeeList);
    }

    const addAttendee = (email: string) => {
        if (!event.attendees) {
            event.attendees = [];
        }

        const cleanEmail = email.trim().toLowerCase();
        if (email.indexOf('@') === -1) {
            notifications.show(`${email} is not a valid email address`, {
                severity: 'error',
                autoHideDuration: 3 * 1000,
            });
            return;
        }

        const notYetInList = event.attendees.filter(a => a.email === cleanEmail).length === 0;
        if (notYetInList) {
            const newAttendeeList = [
                ...event.attendees,
                {
                    profileId: null,
                    name: null,
                    email: email,
                    optional: false,
                    user: false,
                    organizer: false,
                    resource: false,
                    status: "",
                },
            ];

            setEventProperty("attendees", newAttendeeList);
        }

        setEmailInputValue("");
    }

    const formatStatus = (attendee: ScheduleEventAttendee) => {
        if (attendee.status === "needsAction") {
            return "Awaiting";
        } else {
            return attendee.status;
        }
    }

    const onlyTheOwnerCanInviteGuestWarning = () => {
        return (
            <Stack direction="column" sx={{alignItems: "center"}}>
                <Typography variant="body1">Only the owner of this event can invite guests.</Typography>
            </Stack>
        );
    }

    const tooManyAttendeesWarning = () => {
        return (
            <Stack direction="column" sx={{alignItems: "center"}}>
                <Typography variant="body1">TeamCal attendee limit reached. Use Google Calendar to manage invites.</Typography>
            </Stack>
        );
    }

    let showWarning;
    if (!event.organizer && !event.canInviteGuests) {
        showWarning = onlyTheOwnerCanInviteGuestWarning();
    } else if (event.attendeesOmitted) {
        showWarning = tooManyAttendeesWarning();
    }

    return (
        <Box sx={{height: 280}}>
            {showWarning !== undefined ? (
                showWarning
            ) : (
                <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
                    <CalendarEmailAutocomplete
                        label="Guest name or email address"
                        inputValue={emailInputValue}
                        onInputValueChanged={(email: string) => {
                            setEmailInputValue(email);
                        }}/>
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<PersonAddIcon/>}
                        disabled={!emailInputValue}
                        aria-label="Invite guest to event"
                        onClick={() => {
                            addAttendee(emailInputValue);
                        }}>
                        Invite
                    </Button>
                </Stack>
            )}
            <List sx={{width: '100%', height: 214, overflowY: "auto"}}>
                {(event.attendees || []).map(attendee => {
                    return (
                        <ListItem
                            key={attendee.email}
                            secondaryAction={
                                <Stack spacing={1} direction="row" sx={{alignItems: "center"}}>
                                    {attendee.status && <Chip sx={{textTransform: "capitalize"}} label={formatStatus(attendee)}/>}
                                    {event.canInviteGuests &&
                                        <Tooltip title="Remove attendee">
                                            <IconButton
                                                edge="end"
                                                color="secondary"
                                                aria-label="Remove attendee"
                                                onClick={() => {
                                                    removeAttendee(attendee.email)
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Stack>
                            }
                        >
                            <ListItemText>
                                {attendee.name || attendee.email}
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}
