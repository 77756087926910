import * as React from "react";
import {useCallback, useEffect} from "react";
import {AppBar, Box, Container, Paper, Stack, Tab, Tabs, Toolbar, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useSearchParams} from "react-router";
import {LogoutButton} from "./logout-button";
import {TabPanel} from "../../utils/tab-panel";
import {TabProfile} from "./tab-profile/tab-profile";
import {useAuth} from "../../auth/auth-provider";
import {hasFeature} from "../../utils/auth";
import {TabRestrictions} from "./tab-restrictions/tab-restrictions";
import {TabUsers} from "./tab-users/tab-users";
import {TabSubscription} from "./tab-subscription/tab-subscription";
import {useNotifications} from "@toolpad/core";
import {intercomSendEvent} from "../../clients/intercom-api";

const TabMapping: Record<string, number> = {
    "profile": 0,
    "subscription": 1,
    "users": 2,
}

export function AccountRoute() {
    const {authContext} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const notifications = useNotifications();
    const [searchParams] = useSearchParams();
    const preSelectedTab = searchParams.get("tab");

    const previousPathname = location.state?.pathname;
    const showRestrictions = authContext?.account?.isOwner && hasFeature("restrictions", authContext);

    const [tabValue, setTabValue] = React.useState(preSelectedTab ? TabMapping[preSelectedTab] : 0);

    const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const subscriptionPurchased = useCallback(() => {
        notifications.show("Subscription purchased", {
            key: "subscription-purchased",
            severity: "success",
            autoHideDuration: 3000,
        });

        intercomSendEvent("account-subscription-upgraded");
    }, [notifications]);

    useEffect(() => {
        let ignore = false;
        if(!ignore && searchParams.get("payment") === "successful") {
            subscriptionPurchased();
        }

        return () => {ignore = true};
    }, [subscriptionPurchased, searchParams]);

    return (
        <Box>
            <Paper square elevation={1} sx={{pb: 3}}>
                <AppBar position="static">
                    <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                        <Typography variant="h6">TeamCal</Typography>
                        <span style={{flexGrow: 1}}></span>
                        <Stack direction="row" spacing={1}>
                            <Button sx={{color: "primary.contrastText"}} color="secondary" onClick={() => {
                                navigate(previousPathname || "/schedules");
                            }}>Back</Button>
                            <LogoutButton/>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Profile"/>
                            {authContext?.account?.isOwner &&
                                <Tab label="Account & Subscription"/>
                            }
                            {authContext?.account?.isOwner &&
                                <Tab label="Users"/>
                            }
                            {showRestrictions &&
                                <Tab label="Restrictions"/>
                            }
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <TabProfile/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <TabSubscription />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <TabUsers/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <TabRestrictions/>
                    </TabPanel>
                </Container>
            </Paper>
        </Box>
    );
}
