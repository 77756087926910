import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {deleteSchedule, getSchedules, ScheduleInfo, updateSchedule,} from "../../clients/teamcal-api";
import {compareStringDate} from "../../utils/date";
import {Box} from "@mui/material";
import {useDialogs, useNotifications} from "@toolpad/core";
import {ScheduleCardView} from "./schedule-card-view";
import {ScheduleListView} from "./schedule-list-view";
import {useNavigate} from "react-router";
import {showNonResourceAPIErrorNotification} from "../../clients/teamcal-api-notifications";

export enum ViewMode {
    C = "Compact",
    L = "List",
}

export interface ScheduleInfoWithActions extends ScheduleInfo {
    toggleIsPrivate: () => void
    deleteSchedule: () => void
    openSchedule: () => void
}

export function SchedulesView({viewMode}: { viewMode: ViewMode }) {
    const [schedules, setSchedules] = useState<ScheduleInfo[]>([]);
    const dialogs = useDialogs();
    const navigate = useNavigate();
    const notifications = useNotifications();

    const loadSchedules = useCallback(async () => {
        try {
            const schedules = await getSchedules();

            const sortedSchedules = schedules.sort((s1, s2) => {
                return s1.name.localeCompare(s2.name) || -compareStringDate(s1.createdOn, s2.createdOn);
            });

            return sortedSchedules.map(s => {
                return {
                    ...s,
                    name: s.name || "Untitled schedule",
                }
            });
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
            return [];
        }
    }, [notifications]);

    const handleToggleIsPrivate = async (info: ScheduleInfo) => {
        info.isPrivate = !info.isPrivate;

        setSchedules(schedules.map(existingInfo => {
            if (existingInfo.id === info.id)
                return info;
            else
                return existingInfo;
        }));

        try {
            await updateSchedule(info.id, {private: info.isPrivate});
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    const handleDeleteSchedule = async (info: ScheduleInfo) => {
        const confirmed = await dialogs.confirm(
            "Confirm to delete this team schedule. " +
            "The linked Google Calendar data will not be deleted.", {
                okText: "Delete",
                cancelText: "Cancel",
            });

        if (confirmed) {
            try {
                await deleteSchedule(info.id);
                setSchedules(schedules.filter(existingInfo => existingInfo.id !== info.id));
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        }
    }

    const openSchedule = (info: ScheduleInfo) => {
        navigate(`/schedules/${info.id}`);
    }

    useEffect(() => {
        let ignore = false;
        loadSchedules().then(schedules => {
            if (!ignore) {
                setSchedules(schedules);
            }
        })

        return () => {ignore = true};
    }, [loadSchedules]);

    const schedulesWithActions = schedules.map(s => {
        return {
            ...s,
            toggleIsPrivate: async () => {
                await handleToggleIsPrivate(s);
            },
            deleteSchedule: async () => {
                await handleDeleteSchedule(s);
            },
            openSchedule: () => {
                openSchedule(s);
            },
        }
    });

    return <Box>
        {viewMode === ViewMode.C ? <ScheduleCardView schedules={schedulesWithActions}/> :
            <ScheduleListView schedules={schedulesWithActions}/>}
    </Box>;
}
