export function binarySearch<T>(arr: T[], compareFn: (other: T) => number): [number, number] {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const comparison = compareFn(arr[mid]);

        if (comparison === 0) {
            // Exact match found
            return [mid, mid];
        } else if (comparison < 0) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }

    // No exact match found.
    // left is the insertion point, right = left - 1.

    // Handle out-of-bounds cases:
    if (left >= arr.length) {
        // Target is greater than all elements
        return [arr.length - 1, arr.length];
    }

    if (right < 0) {
        // Target is smaller than all elements
        return [-1, 0];
    }

    // Both indexes are within range or just at the boundaries.
    // right < left by definition, they bracket the insertion point.
    return [right, left];
}
