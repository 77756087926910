import * as React from "react";
import {RouteObject} from "react-router";
import {Root} from "./routes/root";
import {SchedulesRoute} from "./routes/schedules/schedules-route";
import {Login} from "./routes/auth/login";
import {RequireAuth} from "./routes/auth/require-auth";
import {Signup} from "./routes/auth/signup";
import {Redirect} from "./routes/redirect";
import {ScheduleRoute} from "./routes/schedule/schedule-route";
import {LoginIFrame} from "./routes/auth/login-iframe";
import {AccountRoute} from "./routes/account/account-route";
import {SharedScheduleRoute} from "./routes/shared-schedule/shared-schedule-route";
import {SharedAuthProvider} from "./auth/shared-auth-provider";
import {TeamCalErrorBoundary} from "./utils/error-boundary.";
import {Route404} from "./routes/route-404";

export const routes: RouteObject[] = [
    {
        path: "/",
        element: <Root/>,
        errorElement: <TeamCalErrorBoundary />,
        children: [
            {
                index: true,
                element: <Redirect redirectTo="schedules"/>,
            },
            {
                index: true,
                path: "signup",
                element: <Signup/>,
            },
            {
                path: "login",
                element: <Login/>,
            },
            {
                path: "iframelogin",
                element: <LoginIFrame/>,
            },
            {
                path: "schedules",
                element: (
                    <RequireAuth>
                        <SchedulesRoute/>
                    </RequireAuth>
                ),
            },
            {
                path: "schedules/:scheduleId",
                element: (
                    <RequireAuth>
                        <ScheduleRoute/>
                    </RequireAuth>
                ),
            },
            {
                path: "sharing/:token",
                element: (
                    <SharedAuthProvider>
                        <RequireAuth>
                            <SharedScheduleRoute/>
                        </RequireAuth>
                    </SharedAuthProvider>
                ),
            },
            {
                path: "account",
                element: (
                    <RequireAuth>
                        <AccountRoute/>
                    </RequireAuth>
                ),
            },
            {
                path: "*",
                element: <Route404 />
            }
        ],
    },
]
