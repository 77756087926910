import * as React from "react";
import {useState} from "react";
import {Datepicker, luxonTimezone, MbscDatepickerChangeEvent} from "@mobiscroll/react";
import {Box, Divider, IconButton, Stack, Tooltip} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import {DateTime} from "luxon";
import {useLocale} from "../../locale-provider";

export function DateSelector({selectedDate, onChange}: {
    selectedDate: string,
    onChange: (date: string) => void,
}) {
    const locale = useLocale();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (args: MbscDatepickerChangeEvent) => {
        handleClose();
        onChange(args.value as string);
    }

    const handleToday = () => {
        handleClose();
        onChange(DateTime.now().toISODate());
    }

    return (
        <Box>
            <Tooltip title="Select date">
                <IconButton onClick={handleOpenClick} color="secondary">
                    <TodayIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{p: 2, minWidth: 281, minHeight: 362}}>
                    <Stack direction="column" spacing={1}>
                        <Datepicker
                            locale={locale.mbscLocale}
                            controls={["calendar"]}
                            touchUi={false}
                            returnFormat="iso8601"
                            display="inline"
                            select="date"
                            theme="teamcal"
                            themeVariant="light"
                            timezonePlugin={luxonTimezone}
                            dataTimezone="utc"
                            displayTimezone={locale.uiTimezone}
                            calendarSize={1}
                            calendarType="month"
                            showWeekNumbers={locale.showWeekNumbers}
                            calendarScroll="horizontal"
                            showOuterDays={true}
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                        <Divider/>
                        <Button color="secondary" onClick={handleToday}>Today</Button>
                    </Stack>
                </Box>
            </Popover>
        </Box>
    );
}
