import * as React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {DialogProps} from "@toolpad/core";
import {MbscScheduleResource} from "../utils/utils";
import {useEffect} from "react";

export function RenameRowDialog({payload, open, onClose}: DialogProps<MbscScheduleResource, string | undefined>) {
    const [name, setName] = React.useState<string>(payload.name as string);
    const setRowNameRef = React.useRef<HTMLDivElement | null>(null);

    const handleCancel = async () => {
        await onClose(undefined);
    }

    const handleRename = async () => {
        await onClose(name);
    }

    useEffect(() => {
        // Somehow doesn't work without a timeout
        const timeout = setTimeout(() => {
            if (setRowNameRef.current) {
                setRowNameRef.current.focus();
            }
        });

        return () => clearTimeout(timeout);
    }, []);

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle>
                Rename row
            </DialogTitle>
            <DialogContent>
                <TextField
                    hiddenLabel
                    inputRef={setRowNameRef}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth={true}
                    size="small"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                        if (e.key === "Enter" || e.key === "NumpadEnter") {
                            await handleRename();
                        }
                    }}
                >
                </TextField>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleCancel} color="secondary">Cancel</Button>
                    <Button onClick={handleRename} variant="contained" sx={{ml: 1}}>Rename</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
