import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {DialogProps, useNotifications} from "@toolpad/core";
import {
    createSharedScheduleToken,
    deleteSharedScheduleToken,
    getSharedScheduleToken,
    SharedScheduleToken,
    updateSharedScheduleToken
} from "../../../clients/teamcal-api";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {intercomSendEvent} from "../../../clients/intercom-api";

export type ShareScheduleDialogPayload = {
    scheduleId: string;
    featureAvailable: boolean;
}

export enum CloseAction {
    None,
    Upgrade,
}

export type ClosePayload = {
    type: CloseAction;
}

const LearnMoreUrl = "https://www.teamcalapp.com/schedule-sharing-online-or-on-tv-screens/";
const AdvancedOptionsUrl = "https://www.teamcalapp.com/schedule-sharing-online-or-on-tv-screens/#customize";

export function ShareScheduleDialog({payload, open, onClose}: DialogProps<ShareScheduleDialogPayload, ClosePayload>) {
    const notifications = useNotifications();
    const tokenInputRef = useRef<HTMLInputElement>(null);
    const [token, setToken] = useState<SharedScheduleToken | undefined>(undefined);

    const createLink = async () => {
        try {
            const t = await createSharedScheduleToken(payload.scheduleId);
            setToken(t);
            intercomSendEvent("schedule-sharing-created", {"id": payload.scheduleId, "url": t});
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    const updateLink = async () => {
        if (token) {
            try {
                const hideEventNames = !token.hideEventNames;
                await updateSharedScheduleToken(payload.scheduleId, token.token, {hideEventNames: hideEventNames});
                setToken({
                    ...token,
                    hideEventNames: hideEventNames,
                });
                intercomSendEvent("schedule-sharing-updated", {"id": payload.scheduleId});
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        }
    }

    const deleteLink = async () => {
        if (token) {
            try {
                await deleteSharedScheduleToken(payload.scheduleId, token.token);
                setToken(undefined);
                intercomSendEvent("schedule-sharing-disabled", {"id": payload.scheduleId});
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        }
    }

    const handleCancel = async () => {
        await onClose({type: CloseAction.None});
    };

    const handleUpgrade = async () => {
        await onClose({type: CloseAction.Upgrade});
    }

    const buildSharingLink = () => {
        if (token) {
            return `${window.location.origin}/sharing/${token.token}`;
        }
    }

    const selectLinkText = () => {
        if (tokenInputRef.current && token && token.token) {
            tokenInputRef.current!.select();
        }
    }

    const handleCopyToClipboard = async () => {
        const link = buildSharingLink();
        if (link) {
            selectLinkText();
            await navigator.clipboard.writeText(link);
            notifications.show("Copied", {severity: 'success', autoHideDuration: 3000});
        }
    }

    useEffect(() => {
        let ignore = false;
        if (payload.featureAvailable) {
            getSharedScheduleToken(payload.scheduleId).then(token => {
                if (!ignore) {
                    setToken(token);
                }
            }).catch(error => {
                showNonResourceAPIErrorNotification(error, notifications);
            });
        }

        return () => {
            ignore = true
        };
    }, [payload.featureAvailable, payload.scheduleId, notifications]);

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Share Schedule
            </DialogTitle>
            <DialogContent>
                {payload.featureAvailable ? (
                    <Box>
                        <Box sx={{pt: 2, pb: 2}}>
                            <Typography variant="body1">Share your schedule by creating a shared link. Everyone with the
                                link has read access to your schedule.</Typography>
                        </Box>
                        {token === undefined || !token.token ? (
                            <Box sx={{pt: 2, pb: 2, display: "flex", justifyContent: "center"}}>
                                <Button startIcon={<LinkIcon/>} variant="contained" onClick={createLink}>
                                    Create Link
                                </Button>
                            </Box>
                        ) : (
                            <Box sx={{pt: 2, pb: 2}}>
                                <Stack direction="row" spacing={1}>
                                    <TextField
                                        hiddenLabel
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth={true}
                                        size="small"
                                        value={buildSharingLink()}
                                        onClick={selectLinkText}
                                        inputRef={tokenInputRef}
                                        slotProps={{
                                            input: {
                                                readOnly: true
                                            }
                                        }}
                                    ></TextField>
                                    <Tooltip title="Copy link">
                                        <IconButton color="secondary" onClick={handleCopyToClipboard}>
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete link">
                                        <IconButton color="secondary" onClick={deleteLink}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <FormGroup sx={{pt: 2}}>
                                    <FormControlLabel
                                        control={<Switch checked={token.hideEventNames} onChange={updateLink}/>}
                                        label="Hide event titles & texts on shared schedule"
                                    />
                                </FormGroup>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box>
                        <Box sx={{pt: 2, pb: 2}}>
                            <Typography variant="body1">Upgrade your TeamCal subscription to "Premium" or "Business" to
                                share your schedule by creating a shared link.</Typography>
                        </Box>
                        <Stack direction="row" spacing={1}
                               sx={{pt: 2, pb: 2, display: "flex", justifyContent: "center"}}>
                            <Button
                                startIcon={<ShoppingCartIcon/>}
                                variant="contained"
                                onClick={handleUpgrade}>
                                Upgrade
                            </Button>
                            <Button
                                target="_blank"
                                rel="noopener"
                                href={LearnMoreUrl}
                                color="secondary">
                                Learn More
                            </Button>
                        </Stack>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    {payload.featureAvailable &&
                        <Button
                            target="_blank"
                            rel="noopener"
                            href={AdvancedOptionsUrl}
                            color="secondary">
                            Advanced Options
                        </Button>
                    }
                    <Button onClick={handleCancel} sx={{ml: 1}}>Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
