import * as React from "react";
import {Box, Typography} from "@mui/material";
import {PricingTable, SubscriptionPlan} from "./pricing-table";
import {createPaymentSession} from "../../../clients/teamcal-api";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {useNotifications} from "@toolpad/core";
import {intercomSendEvent} from "../../../clients/intercom-api";

export function TrialUpgrade() {
    const notifications = useNotifications();

    const handleUpgrade = async (plan: SubscriptionPlan, interval: number) => {
        try {
            const session = await createPaymentSession(plan.plan, interval);
            intercomSendEvent("account-subscription-upgrade-checkout", {"plan": plan, "interval": interval});
            window.location.replace(session.stripeUrl);
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    return (
        <Box>
            <Box sx={{textAlign: "center", mb: 4}}>
                <Typography variant="body2">
                    You are currently on a free trial and benefit from all features of the business subscription.
                    Upgrade now to continue using TeamCal.
                </Typography>
            </Box>
            <PricingTable onSubscribe={handleUpgrade}/>
            <Box sx={{textAlign: "center", mt: 4}}>
                <Typography variant="body2">
                    Subscription plan pricing is for the whole organization.<br/>
                    You can invite as many users as you like to your paid account.
                </Typography>
                <Typography variant="body2" sx={{mt: 2}}>
                    <a href="mailto:info@teamcalapp.com">Contact us</a> for Enterprise & Education plans or if
                    you need assistance.
                </Typography>
            </Box>
        </Box>
    );
}
