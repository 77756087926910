import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import * as React from "react";

export function AddCalendarArrow({onAddCalendar}: { onAddCalendar: () => Promise<void> }) {
    return (
        <Box sx={{height: "100%"}}>
            <Box className="tc-add-calendars"
                 sx={{
                     height: "100%",
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "center"
                 }}>
                <Typography variant="h5" sx={{mb: 4}}>
                    Add calendars to start!
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onAddCalendar}
                    startIcon={<PersonAddIcon/>}
                >
                    Add Calendars
                </Button>
            </Box>
        </Box>
    );
}

export const MemoAddCalendarArrow = React.memo(AddCalendarArrow);
