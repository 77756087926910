import * as React from "react";
import {Box, styled, TextField, Typography} from "@mui/material";
import {useRef} from "react";

const WhiteBorderTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: white;
        }

        &:hover fieldset {
            border-color: white;
        }
    }

    & .MuiInputBase-input {
        padding-left: 6px;
        padding-right: 6px;
    }
`;

const CleanRegex = /(?:\r\n|\r|\n)/g;

export function EditBox({value, disabled, onChange}: {
    value: string,
    disabled?: boolean,
    onChange: (value: string) => void,
}) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [showTextField, setShowTextField] = React.useState(false);
    const [inEditMode, setInEditMode] = React.useState(false);
    const [textValue, setTextValue] = React.useState(value);

    const cleanText = (value: string) => {
        return value.replace(CleanRegex, "");
    }

    const onEnter = () => {
        setShowTextField(true);
    }

    const onFocus = () => {
        setInEditMode(true);
    }

    const onLeave = () => {
        if (!inEditMode) {
            setShowTextField(false);
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const element = inputRef.current;
        if (element) {
            if (e.key === "Enter" || e.key === "NumpadEnter") {
                e.preventDefault();
                triggerBlur(element);
            } else if (e.key === "Escape") {
                e.preventDefault();
                setTextValue(value);
                setInEditMode(false);
                triggerBlur(element);
            }
        }
    }

    const triggerBlur = (element: HTMLInputElement) => {
        setTimeout(() => {
            element.blur();
        }, 0);
    }

    const onBlur = () => {
        const outputValue = cleanText(textValue);
        if (inEditMode && value !== outputValue) {
            onChange(outputValue);
        }

        setInEditMode(false);
        setShowTextField(false);
    }

    if (!disabled && showTextField) {
        return (
            <WhiteBorderTextField
                hiddenLabel
                variant="outlined"
                autoComplete="off"
                fullWidth={false}
                size="small"
                value={textValue}
                placeholder={value || "Untitled schedule"}
                inputRef={inputRef}
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseLeave={onLeave}
                onKeyDown={onKeyDown}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTextValue(e.target.value);
                }}
                sx={{pl: "6px"}}
                slotProps={{
                    input: {
                        sx: {
                            minWidth: 100,
                            color: "primary.contrastText",
                            fontSize: "20px",
                            fontWeight: "500",
                        }
                    }
                }}
            >
            </WhiteBorderTextField>
        );
    } else {
        return (
            <Box
                minWidth={100}
                onMouseEnter={onEnter}
                sx={{pl: "12px"}}
            >
                <Typography variant="h6">{value || "Untitled schedule"}</Typography>
            </Box>
        );
    }
}
