import * as React from "react";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {useDialogs, useNotifications} from "@toolpad/core";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {deleteAccount, getSubscription, Subscription} from "../../../clients/teamcal-api";
import {useAuth} from "../../../auth/auth-provider";
import {TrialUpgrade} from "./trial-upgrade";
import {ManageSubscription} from "./manage-subscription";
import {intercomSendEvent} from "../../../clients/intercom-api";

export function TabSubscription() {
    const {authContext} = useAuth();
    const dialogs = useDialogs();
    const notifications = useNotifications();

    const [subscription, setSubscription] = useState<Subscription | undefined>();

    const account = authContext?.account!;

    const handleDeleteAccount = async () => {
        const result = await dialogs.confirm(
            "Confirm if you want to delete your account. A deleted account cannot be restored.",
            {
                title: "Delete account",
                okText: "Delete",
                cancelText: "Cancel",
            });

        if (result) {
            try {
                await deleteAccount();
                intercomSendEvent("account-deleted");
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications)
            }

            await dialogs.alert("You account has been deleted.", {title: "Delete account"});
            window.location.replace('https://www.teamcalapp.com');
        }
    }

    useEffect(() => {
        let ignore = false;
        getSubscription().then(subscription => {
            if (!ignore) {
                setSubscription(subscription);
            }
        })

        return () => {
            ignore = true
        };
    }, []);

    const isTrial = subscription && subscription.plan === "trial";

    return (
        <Box>
            {account.isOrgAccount ? (
                <Box>Subscription plan is managed by the organization account.</Box>
            ) : (
                <Box>
                    {subscription !== undefined &&
                        <Box>
                            {isTrial ? <TrialUpgrade/> : <ManageSubscription subscription={subscription}/>}
                        </Box>
                    }
                </Box>
            )}
            <Button
                sx={{mt: 4}}
                color="error"
                onClick={handleDeleteAccount}>
                Delete account
            </Button>
        </Box>
    );
}
