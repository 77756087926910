import * as React from "react";
import {Card, CardActions, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText,} from "@mui/material";
import CalendarToday from "@mui/icons-material/CalendarToday";
import {ScheduleActions} from "./schedule-actions";
import {ScheduleInfoWithActions} from "./schedules-view";
import Button from "@mui/material/Button";

export function ScheduleCard({info}: { info: ScheduleInfoWithActions }) {
    return <Card raised={true} sx={{m: 1, width: 350, height: 350, display: "flex", flexDirection: "column"}}>
        <CardHeader title={info.name} titleTypographyProps={{noWrap: true}}
                    sx={{"& .MuiCardHeader-content": {display: "contents"}}}></CardHeader>
        <CardContent sx={{flexGrow: 1, pt: 0, pb: 0}}>
            <List dense sx={{pt: 0}}>
                {info.rowNames.map((name, i) => {
                    return <ListItem key={i} sx={{pl: 0}}>
                        <ListItemIcon><CalendarToday/></ListItemIcon>
                        <ListItemText primaryTypographyProps={{noWrap: true}}>{name}</ListItemText>
                    </ListItem>;
                })}
                {info.rowCount - info.rowNames.length > 0 &&
                    <ListItem sx={{pl: 0}}>
                        <ListItemText inset
                                      sx={{fontStyle: "italic"}}>{info.rowCount - info.rowNames.length} more</ListItemText>
                    </ListItem>
                }
            </List>
        </CardContent>
        <CardActions sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
            <Button onClick={info.openSchedule}>Open</Button>
            <ScheduleActions info={info}/>
        </CardActions>

    </Card>;
}
