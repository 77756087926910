import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import {IconButton, Tooltip} from "@mui/material";
import {useSearchParams} from "react-router";
import {parseBoolFromUrl} from "../../../shared-schedule/settings-parser";
import {DateTime} from "luxon";

const AUTO_REFRESH_PERIOD = 1000 * 60 * 5; // 5 Minutes

export function AutoRefresh({disable, onRefresh, onDateChange}: {
    disable?: boolean,
    onRefresh: () => void,
    onDateChange: (date: string) => void
}) {
    const [searchParams] = useSearchParams();
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(() => {
        return parseBoolFromUrl("refresh", false, searchParams);
    });

    const handleToggleAutoRefresh = () => {
        setAutoRefreshEnabled(!autoRefreshEnabled);
    }

    const executeInterval = useCallback(() => {
        onRefresh();
        onDateChange(DateTime.now().toISODate());
    }, [onRefresh, onDateChange]);

    useEffect(() => {
        if (autoRefreshEnabled) {
            const id = setInterval(executeInterval, AUTO_REFRESH_PERIOD);
            return () => clearInterval(id);
        }
    }, [autoRefreshEnabled, executeInterval]);

    const title = `${autoRefreshEnabled ? "Disable" : "Enable"} auto refresh view`;
    return (
        <Tooltip title={title}>
                <span>
                    <IconButton sx={{outline: autoRefreshEnabled ? 1 : null}}
                                color={autoRefreshEnabled ? "primary" : "secondary"}
                                disabled={disable}
                                onClick={handleToggleAutoRefresh}>
                        <AutoRenewIcon/>
                    </IconButton>
                </span>
        </Tooltip>
    );
}
