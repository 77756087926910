import * as React from "react";
import {createContext, ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {getSchedule, Schedule, TeamCalAPIError, updateSchedule} from "../../clients/teamcal-api";
import {showNonResourceAPIErrorNotification} from "../../clients/teamcal-api-notifications";
import {useNotifications} from "@toolpad/core";
import {mapLegacyViewScale, ViewTimeframes} from "./event-calendar/toolbar/view-timeframe-selector";
import {useNavigate} from "react-router";
import {Box} from "@mui/material";
import {intercomSendEvent} from "../../clients/intercom-api";

export type ScheduleChange = {
    prop: string;
    value: any;
}

type ScheduleCtx = {
    data: Schedule;
    applyChanges: (changes: ScheduleChange[]) => void;
}

export const ScheduleContext = createContext<ScheduleCtx | undefined>(undefined);

export function ScheduleProvider({scheduleId, children}: { scheduleId: string, children: ReactElement }) {
    const notifications = useNotifications();
    const navigate = useNavigate();
    const [data, setData] = useState<Schedule | undefined>(undefined);

    const handleScheduleChanges = useCallback(async (changes: ScheduleChange[]) => {
        try {
            const mergedChanges = Object.assign({}, ...changes.map(({prop, value}) => ({[prop]: value})));

            setData(schedule => ({
                ...schedule!,
                ...mergedChanges,
            }));

            await updateSchedule(scheduleId, mergedChanges);
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }, [scheduleId, notifications]);

    useEffect(() => {
        let ignore = false;
        getSchedule(scheduleId).then(schedule => {
            if (!ignore) {
                // Convert legacy viewScale
                if (!schedule.viewTimeframe) {
                    schedule.viewTimeframe = mapLegacyViewScale(schedule.viewScale).key;
                    if (schedule.viewScale.startsWith("WW")) {
                        schedule.showWeekends = false;
                    }
                }

                if (!schedule.viewZoom) {
                    const viewTimeframe = ViewTimeframes.find(v => v.key === schedule.viewTimeframe);
                    if (viewTimeframe) {
                        schedule.viewZoom = viewTimeframe.defaultZoom;
                    }
                }

                setData(schedule);
                intercomSendEvent("schedule-open", {"id": scheduleId, "name": schedule.name});
            }
        }).catch(error => {
            if (error instanceof TeamCalAPIError && error.details.code === "ScheduleNotFound") {
                navigate("/schedules");
            } else {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        });

        return () => {
            ignore = true
        };
    }, [scheduleId, navigate, notifications]);

    useEffect(() => {
        document.title = data?.name ? data?.name : "TeamCal App";
    }, [data?.name]);

    if (!data) {
        // Loading
        return (
            <Box></Box>
        );
    } else {
        return (
            <ScheduleContext.Provider value={{data, applyChanges: handleScheduleChanges}}>
                {children}
            </ScheduleContext.Provider>
        );
    }
}

export function useSchedule(): ScheduleCtx {
    const ctx = useContext(ScheduleContext);
    if (!ctx) {
        throw new Error("useSchedule can only be used in a ScheduleProvider tree");
    }

    return ctx;
}
