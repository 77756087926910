import {AppBar, Box, Stack, Toolbar, Typography} from "@mui/material";
import {EditBox} from "./utils/edit-box";
import Button from "@mui/material/Button";
import * as React from "react";
import {useSchedule} from "./schedule-provider";
import {useLocation, useNavigate} from "react-router";
import {PurchaseButton} from "../../utils/purchase-button";
import {updateUserSettings} from "../../clients/teamcal-api";

export function SchedulingAppBar() {
    const navigate = useNavigate();
    const schedule = useSchedule();
    const location = useLocation();

    const handleSwitchToOldUI = async () => {
        await updateUserSettings({newUI: false});
        window.location.reload();
    }

    return (
        <AppBar position="static" className="tc-hidden-print">
            <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                <Typography variant="h6">TeamCal</Typography>
                {schedule && (
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {schedule.data.name && <Typography variant="h6">:</Typography>}
                        <EditBox
                            value={schedule.data.name}
                            onChange={(value: string) => {
                                schedule.applyChanges([{prop: "name", value: value}]);
                            }}
                        />
                    </Box>
                )}
                <span style={{flexGrow: 1}}></span>
                <Stack direction="row" spacing={1}>
                    <Button sx={{color: "primary.contrastText", mr: 4}}
                            color="alternate"
                            variant="contained"
                            onClick={handleSwitchToOldUI}>
                        Switch back to Old UI
                    </Button>
                    <PurchaseButton/>
                    <Button sx={{color: "primary.contrastText"}} color="secondary" onClick={() => {
                        navigate(`/schedules/`);
                    }}>Schedules</Button>
                    <Button sx={{color: "primary.contrastText"}} color="secondary" onClick={() => {
                        navigate(`/account/`, {state: {"pathname": location.pathname}});
                    }}>Account</Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
