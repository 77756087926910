import * as React from "react";
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {RowOrderDirections} from "../../utils/utils";

export function RowOrderSelector({onChangeRowOrder}: {onChangeRowOrder: (dir: RowOrderDirections) => Promise<void>}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = async (dir: RowOrderDirections) => {
        handleClose();
        await onChangeRowOrder(dir);
    }

    return (
        <Box>
            <Tooltip title="Change row order">
                <IconButton size="small" onClick={handleClick}>
                    <SwapVertIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={async () => {await handleChange(RowOrderDirections.Up)}}>
                    <ListItemIcon>
                        <ArrowUpwardIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Move Up</ListItemText>
                </MenuItem>
                <MenuItem onClick={async () => {await handleChange(RowOrderDirections.Before)}}>
                    <ListItemIcon>
                        <ArrowRightAltIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Move before...</ListItemText>
                </MenuItem>
                <MenuItem onClick={async () => {await handleChange(RowOrderDirections.Down)}}>
                    <ListItemIcon>
                        <ArrowDownwardIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Move down</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
}
