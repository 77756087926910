import * as React from "react";
import {useEffect} from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid2 as Grid,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import EvenIcon from "@mui/icons-material/Event";
import {EmojiPicker, EmojiPickerSelect} from "../../../../utils/emoji-picker";
import {SetEventProperty} from "../edit-event-dialog";
import {isEventReadOnly, isRowReadOnly, MbscScheduleEvent, MbscScheduleResource} from "../../utils/utils";
import {Datepicker, luxonTimezone, MbscDatepickerChangeEvent, MbscDatepickerOpenEvent} from "@mobiscroll/react";
import {DatepickerBase} from "@mobiscroll/react/dist/src/core/components/datepicker/datepicker";
import {ColorSelector, EventColors} from "../../event-calendar/utils/color-selector";
import {LocaleCtx} from "../../locale-provider";


export function GeneralEventEdit({event, resources, setEventProperty, allowMove, locale}: {
    event: MbscScheduleEvent,
    resources: MbscScheduleResource[],
    setEventProperty: SetEventProperty,
    allowMove: boolean,
    locale: LocaleCtx,
}) {
    const [startRef, setStartRef] = React.useState<HTMLDivElement | null>(null);
    const [endRef, setEndRef] = React.useState<HTMLDivElement | null>(null);
    const [openDatePicker, setOpenDatePicker] = React.useState(false);
    const [datePickerSection, setDatePickerSection] = React.useState<"start" | "end">("start");
    const titleRef = React.useRef<HTMLDivElement | null>(null);
    const resource = resources.find(r => r.id === event.resource);
    const readOnlyEvent = isEventReadOnly(event);

    const handleDatePickerIsOpen = (_args: MbscDatepickerOpenEvent, inst: DatepickerBase) => {
        inst.setActiveDate(datePickerSection);
    }

    const handleDateChanged = (args: MbscDatepickerChangeEvent) => {
        const [start, end] = args.value as string[];
        setEventProperty("start", start);
        setEventProperty("end", end);
        setEventProperty("allDay", event.allDay);
    }

    const handleAllDayChanged = () => {
        const allDay = !(event.allDay || false);
        setEventProperty("allDay", allDay);
    }

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.focus();
        }
    }, []);

    return (
        <Grid container spacing={1}>
            <Grid size={2} display="flex" alignItems="center">
                <Typography variant="body1">Title</Typography>
            </Grid>
            <Grid size={10}>
                <Stack direction="row" spacing={1}>
                    <Paper elevation={0} sx={{display: "flex", alignItems: "center"}}>
                        <ColorSelector
                            tooltipTitle="Set event color"
                            availableColors={Array.from(new Set([resource!.color as string, ...EventColors]))}
                            currentColor={event.color || resource!.color as string}
                            disabled={readOnlyEvent}
                            onChange={async (color: string) => setEventProperty("color", color)}
                            onClose={async () => {
                            }}
                        />
                    </Paper>
                    <TextField
                        hiddenLabel
                        id="event-tile"
                        inputRef={titleRef}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth={true}
                        size="small"
                        value={event.title}
                        disabled={readOnlyEvent}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEventProperty("title", e.target.value)
                        }}
                        slotProps={{
                            input: {
                                endAdornment: <InputAdornment position="end">
                                    <EmojiPicker disabled={readOnlyEvent} onEmojiSelect={(emoji: EmojiPickerSelect) => {
                                        setEventProperty("title", emoji.native + event.title);
                                    }}/>
                                </InputAdornment>,
                            }
                        }}>
                    </TextField>
                </Stack>
            </Grid>
            {!event.outOfOffice &&
                <Grid size={2} display="flex" alignItems="center">
                    <Typography variant="body1">Location</Typography>
                </Grid>
            }
            {!event.outOfOffice &&
                <Grid size={10}>
                    <TextField
                        hiddenLabel
                        id="event-location"
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        fullWidth={true}
                        value={event.location}
                        disabled={readOnlyEvent}
                        sx={{pl: "42px"}}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEventProperty("location", e.target.value)
                        }}>
                    </TextField>
                </Grid>
            }
            <Grid size={2} display="flex" alignItems="center">
                <Typography variant="body1">Row</Typography>
            </Grid>
            <Grid size={10} display="flex" justifyContent="flex-end">
                <FormControl variant="outlined" sx={{minWidth: 250}}>
                    <Select
                        labelId="event-row"
                        variant="outlined"
                        id="event-row-select"
                        size="small"
                        disabled={!allowMove || readOnlyEvent}
                        value={(event.resource || "") as string}
                        onChange={(e: SelectChangeEvent) => {
                            setEventProperty("resource", e.target.value);
                        }}
                    >
                        {!event.resource &&
                            <MenuItem key={""} value={""}></MenuItem>
                        }
                        {resources.sort((r1, r2) => {
                            return r1.name!.localeCompare(r2.name!);
                        }).map(r => {
                            const readOnly = isRowReadOnly(r);

                            return (
                                <MenuItem key={r.id} value={r.id} disabled={readOnly}>
                                    {readOnly && <LockIcon fontSize="small" sx={{mr: 1}}/>}{r.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {resources.filter(r => isRowReadOnly(r)).length > 0 &&
                <Grid size={12} display="flex" alignItems="center" justifyContent="end">
                    <Typography variant="caption" sx={{mb: 1, width: 350}}>
                        You cannot add events to read-only (🔒) rows. Ask the calendar owners to give you write
                        permissions. <a href="https://support.google.com/calendar/answer/37082#existing"
                                        target="_blank" rel="noreferrer">Help</a>.
                    </Typography>

                </Grid>
            }
            <Datepicker
                value={[event.start, event.end]}
                controls={event.allDay ? ["calendar"] : ['calendar', 'timegrid']}
                returnFormat="iso8601"
                select="range"
                touchUi={true}
                isOpen={openDatePicker}
                onOpen={handleDatePickerIsOpen}
                onChange={handleDateChanged}
                onClose={() => {
                    setOpenDatePicker(false)
                }}
                rangeHighlight={true}
                showRangeLabels={true}
                exclusiveEndDates={true}
                startInput={startRef}
                endInput={endRef}
                theme="teamcal"
                themeVariant="light"
                timezonePlugin={luxonTimezone}
                dataTimezone="utc"
                displayTimezone={locale.uiTimezone}
                locale={locale.mbscLocale}
                calendarSize={1}
                calendarType="month"
                showWeekNumbers={locale.showWeekNumbers}
                calendarScroll="horizontal"
                showOuterDays={true}
                stepMinute={15}
            />
            <Grid size={2} display="flex" alignItems="center">
                <Typography variant="body1">Start</Typography>
            </Grid>
            <Grid size={10} display="flex" justifyContent="flex-end">
                <TextField
                    hiddenLabel
                    id="event-start"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    inputRef={setStartRef}
                    disabled={readOnlyEvent}
                    onClick={() => {
                        if (!readOnlyEvent) {
                            setDatePickerSection("start");
                            setOpenDatePicker(true);
                        }
                    }}
                    sx={{width: 250}}
                    slotProps={{
                        input: {
                            sx: {cursor: "pointer"},
                            endAdornment: <EvenIcon color="inherit"/>,
                        }
                    }}>
                </TextField>
            </Grid>
            <Grid size={2} display="flex" alignItems="center">
                <Typography variant="body1">End</Typography>
            </Grid>
            <Grid size={10} display="flex" justifyContent="flex-end">
                <TextField
                    hiddenLabel
                    id="event-end"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    inputRef={setEndRef}
                    disabled={readOnlyEvent}
                    onClick={() => {
                        if (!readOnlyEvent) {
                            setDatePickerSection("end");
                            setOpenDatePicker(true);
                        }
                    }}
                    sx={{width: 250}}
                    slotProps={{
                        input: {
                            sx: {cursor: "pointer"},
                            endAdornment: <EvenIcon color="inherit"/>,
                        }
                    }}>
                </TextField>
            </Grid>
            <Grid size={12} display="flex" justifyContent="flex-end">
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox checked={event.allDay}
                                  onChange={handleAllDayChanged}
                                  disabled={readOnlyEvent}/>
                    } label="All day event"/>
                </FormGroup>
            </Grid>
        </Grid>
    );
}
