import * as React from "react";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";

export function SharedScheduleTokenError() {
    return (
        <Box>
            <AppBar position="static" className="tc-hidden-print">
                <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                    <Typography variant="h6">TeamCal</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{textAlign: "center", pt: 6}}>
                <Typography variant="h6">Shared link is invalid. Contact the owner of this schedule for a new link.</Typography>
            </Box>
        </Box>
    );
}
