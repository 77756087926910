import * as React from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {ResourceFilter} from "./resource-filter";
import {MbscScheduleResource} from "../../utils/utils";
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';

export function ResourceHeader({resources, onFilterChanged, onTotalToggle}: {
    resources: MbscScheduleResource[],
    onFilterChanged: (filteredResourceIds: string[]) => void,
    onTotalToggle: (enabled: boolean) => void,
}) {
    const [showTotal, setShowTotal] = React.useState(false);

    const handleTotalToggleClick = () => {
        const enabled = !showTotal;
        setShowTotal(enabled);
        onTotalToggle(enabled);
    }

    return (
        <Box className="tc-hidden-print" display="flex" alignItems="center" justifyContent="flex-end" sx={{height: "100%"}}>
            <ResourceFilter resources={resources} onFilterChanged={onFilterChanged}/>
            <Tooltip title={`${showTotal ? "Hide" : "Show"} event duration summary`} color="secondary">
                <IconButton sx={{padding: 0, ml: 0.5}} onClick={handleTotalToggleClick}>
                    {showTotal ? <TimerOffIcon/> : <TimerIcon/>}
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export const MemoResourceHeader = React.memo(ResourceHeader);
