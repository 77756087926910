import * as React from "react";
import {DialogProps} from "@toolpad/core";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

export type ConfigureSSODialogPayload = {
    isEnabled: boolean
}

export function ConfigureSSODialog({
                                       payload,
                                       open,
                                       onClose
                                   }: DialogProps<ConfigureSSODialogPayload, boolean | undefined>) {
    const handleCancel = async () => {
        await onClose(undefined);
    };

    const handleEnable = async () => {
        await onClose(true);
    };

    const handleDisable = async () => {
        await onClose(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Configure Single Sign-On (SSO)
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box sx={{pt: 2, pb: 2}}>
                        <Typography variant="body1">
                            Enable SSO to give all your G-Suite users access to your TeamCal account. A G-Suite
                            account is required to enable SSO. Personal GMail accounts are not supported.
                        </Typography>
                        <Typography variant="body2" sx={{mt: 2}}>
                            💡 Users need to belong to your G-Suite organization to access your account with SSO.
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button color="secondary" onClick={handleCancel} sx={{ml: 1}}>Cancel</Button>
                    {payload.isEnabled &&
                        <Button variant="contained" onClick={handleDisable} sx={{ml: 1}}>Disable</Button>
                    }
                    {!payload.isEnabled &&
                        <Button variant="contained" onClick={handleEnable} sx={{ml: 1}}>Enable</Button>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}
