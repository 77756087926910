import * as React from "react";
import {DialogProps, useNotifications} from "@toolpad/core";
import {Schedule} from "../../../clients/teamcal-api";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid2 as Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {formatWorkDayHour, Timezones, WeekDays} from "../../../utils/date";

const WorkHours = Array.from(new Array(24), (_, i) => i * 60);

export type SettingDialogPayload = {
    schedule: Schedule;
}

export type SettingDialogSavePayload = {
    timezone?: string;
    workWeekStart: number;
    workWeekEnd: number;
    workDayStart: number;
    workDayEnd: number;
    moveAction: string;
}

export function SettingDialog({
                                  payload,
                                  open,
                                  onClose
                              }: DialogProps<SettingDialogPayload, SettingDialogSavePayload | undefined>) {
    const notifications = useNotifications();
    const [timezone, setTimezone] = React.useState<string>(payload.schedule.timezone || "");
    const [workWeekStart, setWorkWeekStart] = React.useState<number>(payload.schedule.workWeekStart);
    const [workWeekEnd, setWorkWeekEnd] = React.useState<number>(payload.schedule.workWeekEnd);
    const [workDayStart, setWorkDayStart] = React.useState<number>(payload.schedule.workDayStart);
    const [workDayEnd, setWorkDayEnd] = React.useState<number>(payload.schedule.workDayEnd);
    const [moveAction, setMoveAction] = React.useState<string>(payload.schedule.moveAction);
    const [showAdvancedSettings, setShowAdvancedSettings] = React.useState<boolean>(moveAction !== "M");

    const workWeekError = workWeekStart == workWeekEnd;
    const workDayError = workDayStart >= workDayEnd;

    const handleCancel = async () => {
        await onClose(undefined);
    };

    const handleSave = async () => {
        if (workWeekError || workDayError) {
            notifications.show("Resolve issue to proceed", {severity: "error", autoHideDuration: 3000});
            return;
        }

        await onClose({
            timezone: timezone,
            workWeekStart: workWeekStart,
            workWeekEnd: workWeekEnd,
            workDayStart: workDayStart,
            workDayEnd: workDayEnd,
            moveAction: moveAction,
        });
    }

    const handleAdvancedSettingsVisibilityChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setShowAdvancedSettings(isExpanded);
    }

    const WeekDaySelector = ({value, onChange}: {
        value: number,
        onChange: (e: SelectChangeEvent<number>) => void
    }) => {
        return (
            <FormControl sx={{width: 150}}>
                <Select
                    displayEmpty
                    variant="outlined"
                    value={value}
                    size="small"
                    onChange={onChange}
                >
                    {WeekDays.map((day, i) => {
                        return (
                            <MenuItem key={i} value={i}>{day}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    const WorkDaySelector = ({value, onChange}: {
        value: number,
        onChange: (e: SelectChangeEvent<number>) => void
    }) => {
        return (
            <FormControl sx={{width: 150}}>
                <Select
                    displayEmpty
                    variant="outlined"
                    value={value}
                    size="small"
                    onChange={onChange}
                >
                    {WorkHours.map(hour => {
                        return (
                            <MenuItem key={hour} value={hour}>{formatWorkDayHour(hour)}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Schedule settings
            </DialogTitle>
            <DialogContent>
                <Box sx={{pt: 2, pb: 2}}>
                    <Grid container spacing={1}>
                        <Grid size={2} display="flex" alignItems="center">
                            <Typography variant="body1">Timezone</Typography>
                        </Grid>
                        <Grid size={10} display="flex" alignItems="center" justifyContent="flex-end">
                            <FormControl sx={{width: 330}}>
                                <Select
                                    displayEmpty
                                    variant="outlined"
                                    size="small"
                                    value={timezone}
                                    onChange={(e: SelectChangeEvent) => {
                                        setTimezone(e.target.value);
                                    }}
                                >
                                    <MenuItem key="" value="">Use account default</MenuItem>
                                    {Timezones.map(([zone, offset]) => {
                                        return (
                                            <MenuItem key={zone} value={zone}>({offset}) {zone}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid size={2} display="flex" alignItems="center">
                            <Typography variant="body1">Work week</Typography>
                        </Grid>
                        <Grid size={10} display="flex" alignItems="center" justifyContent="flex-end">
                            <Stack direction="row" spacing={1} alignItems="center" display="flex">
                                <WeekDaySelector
                                    value={workWeekStart}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        setWorkWeekStart(e.target.value as number);
                                    }}
                                />
                                <Typography variant="body1"> to </Typography>
                                <WeekDaySelector
                                    value={workWeekEnd}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        setWorkWeekEnd(e.target.value as number);
                                    }}
                                />
                            </Stack>
                        </Grid>
                        {workWeekError &&
                            <Grid size={12} display="flex" alignItems="start" justifyContent="flex-end" sx={{mb: 1}}>
                                <FormHelperText error={true}>Work week start and end cannot be on the same day</FormHelperText>
                            </Grid>
                        }
                        <Grid size={2} display="flex" alignItems="center">
                            <Typography variant="body1">Work day</Typography>
                        </Grid>
                        <Grid size={10} display="flex" alignItems="center" justifyContent="flex-end">
                            <Stack direction="row" spacing={1} alignItems="center" display="flex">
                                <WorkDaySelector
                                    value={workDayStart}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        setWorkDayStart(e.target.value as number);
                                    }}
                                />
                                <Typography variant="body1"> to </Typography>
                                <WorkDaySelector
                                    value={workDayEnd}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        setWorkDayEnd(e.target.value as number);
                                    }}
                                />
                            </Stack>
                        </Grid>
                        {workDayError &&
                            <Grid size={12} display="flex" alignItems="start" justifyContent="flex-end">
                                <FormHelperText error={true}>Work day end must be after start</FormHelperText>
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Accordion
                    disableGutters
                    expanded={showAdvancedSettings}
                    onChange={handleAdvancedSettingsVisibilityChange}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Advanced
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid size={8} display="flex" alignItems="center">
                                <Typography variant="body1">Action if event is moved to another row</Typography>
                            </Grid>
                            <Grid size={4} display="flex" alignItems="center" justifyContent="flex-end">
                                <FormControl>
                                    <Select
                                        size="small"
                                        variant="outlined"
                                        value={moveAction}
                                        onChange={(e: SelectChangeEvent) => {
                                            setMoveAction(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="M">Move to row</MenuItem>
                                        <MenuItem value="I">Invite member</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleCancel} color="secondary">Cancel</Button>
                    <Button onClick={handleSave} sx={{ml: 1}} color="primary">Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
