import * as React from "react";
import {isEventReadOnly, MbscScheduleEvent} from "../../utils/utils";
import {SetEventProperty} from "../edit-event-dialog";
import {Box} from "@mui/material";
import {RichEdit} from "./rich-edit/rich-edit";

export function DescriptionEventEdit({event, setEventProperty}: {
    event: MbscScheduleEvent,
    setEventProperty: SetEventProperty
}) {
    return (
        <Box>
            <RichEdit
                disabled={isEventReadOnly(event)}
                content={event.description as string || ""}
                onUpdate={(html: string) => {
                setEventProperty("description", html || "")
            }}/>
        </Box>
    )
}
