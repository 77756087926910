import * as React from "react";
import {Navigate, useLocation, useSearchParams} from "react-router";
import {useAuth} from "../../auth/auth-provider";

function authenticate(redirectUrl: string | null) {
    const url = redirectUrl ? `oauth/google?redirect=${encodeURIComponent(redirectUrl)}` : "oauth/google";
    window.location.replace(url);
}

export function Login() {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const redirectUrl = searchParams.get("redirect");
    const forceNewAuthentication = (searchParams.get("force") || "false").toLowerCase() === "true";

    const pathname = location.state?.pathname;

    const {authContext} = useAuth()
    if (!forceNewAuthentication && authContext && authContext.isAuthenticated) {
        return <Navigate to="/"/>
    } else {
        authenticate(pathname || redirectUrl);
    }

    return null;
}
