import DOMPurify from "dompurify";

const GoogleCalendarTags = {
    // Do not show "a" for now
    ALLOWED_TAGS: [
        "p", "br", "span", "div", "strong", "b", "i", "em", "u", "s", "strike", "small", "big", "h1", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li"
    ]
};

export function sanitizeGoogleCalendarHtml(html: string | undefined): string {
    return DOMPurify.sanitize(html || "", GoogleCalendarTags);
}
