import * as React from "react";
import {Box} from "@mui/material";
import {LocaleProvider} from "../schedule/locale-provider";
import {SharedSchedulingView} from "./shared-scheduling-view";
import {SharedScheduleProvider} from "./shared-schedule-provider";
import {useAuth} from "../../auth/auth-provider";
import {SharedAuthInfo} from "../../auth/shared-auth-provider";
import {SchedulingAppBar} from "./scheduling-app-bar";
import {SchedulingFooterBar} from "../schedule/scheduling-footer-bar";

export function SharedScheduleRoute() {
    const {authContext} = useAuth();
    const sharedContext = authContext as SharedAuthInfo;

    return (
        <SharedScheduleProvider sharedData={sharedContext.sharedData!}>
            <LocaleProvider>
                <Box>
                    <SchedulingAppBar/>
                    <SharedSchedulingView/>
                    <SchedulingFooterBar/>
                </Box>
            </LocaleProvider>
        </SharedScheduleProvider>
    );
}
