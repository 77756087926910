import * as React from "react";
import {List} from "@mui/material";
import {ScheduleListItem} from "./schedule-list-item";
import {ScheduleInfoWithActions} from "./schedules-view";

export function ScheduleListView({schedules}: {schedules: ScheduleInfoWithActions[]}) {
    return <List sx={{m: -1}}>
        {schedules.map(scheduleInfo => {
            return <ScheduleListItem key={scheduleInfo.id} info={scheduleInfo}/>
        })}
    </List>;
}
