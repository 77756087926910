import {MbscScheduleEvent} from "../../utils/utils";

export function applyEventFilter(events:  MbscScheduleEvent[], allDaySettings: string|null, outOfOfficeSettings: string|null, filterText?: string)  {
    return events.filter(e => {
        let nameFilter = false;
        if (filterText && e.title !== undefined) {
            filterText.toLowerCase().split(" ").filter(
                filter => filter !== ""
            ).forEach(filter => {
                if (e.title!.toLowerCase().indexOf(filter) >= 0) {
                    nameFilter = true;
                }
            });
        } else {
            nameFilter = true;
        }

        let allDayFilter;
        const allDay = allDaySettings === 'only' ? true : allDaySettings === 'hide' ? false : undefined;
        if (allDay !== undefined) {
            allDayFilter = e.allDay === allDay;
        } else {
            allDayFilter = true;
        }

        let outOfOfficeFilter;
        const outOfOffice = outOfOfficeSettings === 'only' ? true : outOfOfficeSettings === 'hide' ? false : undefined;
        if (outOfOffice !== undefined) {
            outOfOfficeFilter = e.outOfOffice === outOfOffice;
        } else {
            outOfOfficeFilter = true;
        }

        return nameFilter && allDayFilter && outOfOfficeFilter;
    });
}
