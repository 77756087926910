import {asLuxonDate} from "../../utils/date";
import {DateTime} from "luxon";
import {components} from "../../clients/teamcal-api-schema";

function getQueryParam(name: string, params: URLSearchParams): string | null
{
    const value = params.get(name);
    if (Array.isArray(value)) {
        return value ? value[0] : null;
    } else {
        return value;
    }
}

export function parseBoolFromUrl(name: string, defaultVal: boolean | null, params: URLSearchParams): boolean | null
{
    const value = getQueryParam(name, params);
    if (value) {
        const val = value ? value.toLowerCase() : "";
        return val === "true" || val === "active" || val === "enabled";
    } else {
        return defaultVal;
    }
}

export function parseDateFromUrl(name: string, params: URLSearchParams): DateTime
{
    const value = getQueryParam(name, params);
    return value ? asLuxonDate(value) : DateTime.now();
}

export function parseViewScaleFromUrl(params: URLSearchParams): components["schemas"]["ViewScaleEnum"]
{
    const viewScale = getQueryParam("view", params);
    const val = viewScale ? viewScale.toLowerCase() : '';

    switch (val) {
        case "day": return "D";
        case "week": return "W";
        case "workweek": return "WW";
        case "2weeks": return "2W";
        case "month": return "M";
        case "3months": return "3M";
        case "year": return "Y";
        default: return "D";
    }
}

export function parseModeFromUrl(params: URLSearchParams): components["schemas"]["ViewModeEnum"]
{
    const value = getQueryParam("mode", params);
    const val = value ? value.toLowerCase() : "";
    if (val === "compact") {
        return "C";
    } else if (val === "large") {
        return "B";
    } else {
        return "N";
    }
}

export function parseEventFilterFromUrl(params: URLSearchParams): string | null
{
    return getQueryParam("filter", params);
}
