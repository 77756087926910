import * as React from "react";
import {createContext, ReactElement, useContext, useEffect, useState} from "react";
import {Account, AuthenticationRequiredError, getAccount} from "../clients/teamcal-api";
import * as Sentry from "@sentry/react";
import {gaInit} from "../clients/google-analytics";
import {intercomInit} from "../clients/intercom-api";

export class AuthInfo {
    isAuthenticated?: boolean;
    account?: Account;
}

type AuthCtx = {
    authContext: AuthInfo | undefined;
    setAuthContext: React.Dispatch<React.SetStateAction<AuthInfo>>;
}

function initThirdParties(account: Account) {
    gaInit(account.id);
    intercomInit(
        account.id,
        account.intercomId,
        account.createdOn,
        account.fullName,
        account.email,
        account.isOwner,
        account.features.find(f => f === "trial") !== undefined,
        account.trialExpiration,
    );
    Sentry.setUser({
        id: account.id,
        email: account.email,
    });
}

export const AuthContext = createContext<AuthCtx | undefined>(undefined);

export function AuthProvider({children}: { children: ReactElement }) {
    const [authContext, setAuthContext] = useState<AuthInfo>(new AuthInfo());

    useEffect(() => {
        let ignore = false;
        const newContext = new AuthInfo();

        getAccount().then(account => {
            if (!ignore) {
                newContext.isAuthenticated = true;
                newContext.account = account;
                setAuthContext(newContext);
                initThirdParties(account);
            }
        }).catch(error => {
            if (error instanceof AuthenticationRequiredError) {
                newContext.isAuthenticated = false;
                newContext.account = undefined;
                setAuthContext(newContext);
            } else {
                throw error;
            }
        });

        return () => {
            ignore = true
        };
    }, [authContext.isAuthenticated]);

    return (
        <AuthContext.Provider value={{authContext, setAuthContext}}>{children}</AuthContext.Provider>
    );
}

export function useAuth(): AuthCtx {
    const ctx = useContext(AuthContext);

    if (!ctx) {
        throw new Error("useAuth can only be used in a AuthProvider tree");
    }

    return ctx;
}
