import {MbscCalendarEvent, MbscResource} from "@mobiscroll/react";
import {
    AddScheduleRowEvent,
    ScheduleEvent,
    ScheduleEventAttendee,
    ScheduleRow,
    SharedScheduleEvent,
    UpdateRow,
    UpdateScheduleRowEvent
} from "../../../clients/teamcal-api";
import {DateTime} from "luxon";
import {asLuxonDate} from "../../../utils/date";

export interface MbscScheduleResource extends MbscResource {
    extId: string,
    order?: number,
    roles: Set<string>;
    visible: boolean;
}

export interface MbscScheduleEvent extends MbscCalendarEvent {
    isNew: boolean;
    description?: string;
    location?: string;
    canInviteGuests?: boolean;
    declinedEvent?: boolean;
    attendeesOmitted?: boolean;
    attendees?: ScheduleEventAttendee[];
    link?: string;
    organizer?: boolean;
    outOfOffice?: boolean;
    workLocation?: boolean;
    role?: string;
    sharedId?: string;
}

/*
 Sort schedule rows based on their sort order
 */
export function sortResources(r1: MbscScheduleResource, r2: MbscScheduleResource) {
    let order;
    if (r1.order !== undefined && r2.order !== undefined) {
        order = r1.order - r2.order;
    } else if (r1.order !== undefined) {
        order = -1;
    } else if (r2.order !== undefined) {
        order = 1;
    }

    return order || (r1.name || "").localeCompare((r2.name) || "");
}

export enum RowOrderDirections {
    Up,
    Before,
    Down
}

export function applyResourceSortOrder(resources: MbscScheduleResource[], dir: RowOrderDirections, curResourceId: string, beforeResourceId?: string) {
    if (dir === RowOrderDirections.Before && curResourceId === beforeResourceId) {
        return; // Cannot move row onto itself
    }

    const sorted = resources.sort(sortResources);
    let order = 0;
    let matchedPos;
    let beforePos;

    // Assign order to all rows
    for (let pos = 0; pos < sorted.length; pos++) {
        const resource = sorted[pos];

        if (resource.id === curResourceId) {
            matchedPos = pos;
        } else if (dir === RowOrderDirections.Before && resource.id === beforeResourceId) {
            beforePos = pos;
            order += 1;  // Leave gap to insert match
        }

        resource.order = order;
        order += 1;
    }

    if (dir === RowOrderDirections.Up) {
        if (matchedPos !== undefined && matchedPos > 0) {
            // Move 1 up
            sorted[matchedPos - 1].order! += 1;
            sorted[matchedPos].order = matchedPos - 1;
        }
    } else if (dir === RowOrderDirections.Down) {
        if (matchedPos !== undefined && matchedPos < sorted.length - 1) {
            // Move 1 down
            sorted[matchedPos + 1].order! -= 1;
            sorted[matchedPos].order = matchedPos + 1;
        }
    } else if (matchedPos !== undefined && beforePos !== undefined) {
        // Move before item with "id X"
        sorted[matchedPos].order = sorted[beforePos].order! - 1;
    } else if (matchedPos !== undefined) {
        // Move to last item in list
        sorted[matchedPos].order = sorted[sorted.length - 1].order! + 1;
    }
}

/*
 Build a MbscScheduleResource from the getScheduleRows API response.
 */
export function buildFromScheduleRow(row: ScheduleRow): MbscScheduleResource {
    return {
        id: row.id,
        extId: row.extId,
        name: row.name,
        color: row.bgColor,
        order: row.order,
        roles: new Set<string>(),
        visible: true,
    }
}

/*
 Returns true if given resource is read-only
 */
export function isRowReadOnly(row: MbscScheduleResource): boolean {
    return row.roles.size === 1 && !row.roles.has("W");
}

/*
 Build a ScheduleEvent object for use by the updateResource API.
 Only include properties which changed (patch-update).
 */
export function buildUpdateScheduleResource(resource: MbscScheduleResource, changedProps: string []): UpdateRow {
    return Object.fromEntries(changedProps.map((key) => {
        switch (key) {
            case "color": {
                return ["bgColor", resource[key]];
            }
            default: {
                return [key, resource[key]];
            }
        }
    })) as UpdateRow
}

/*
 Google Calendar has a bug when adding description inside the quick edit form it will use new-lines instead HTML <br> line breaks.
 */
function fixGoogleCalendarDescription(description: string): string {
    if (description.search('\n') > -1 && description.search('<br>') === -1) {
        return description.replaceAll('\n', '<br/>');
    } else {
        return description;
    }
}

/*
 Returns a properly formatted ISO-Date (2024-01-01) or ISO-DateTime (2024-01-01T00:00.000Z)
 */
function formatDates(value: string, allDay: boolean) {
    return allDay ? asLuxonDate(value).toUTC().toISODate() as string : DateTime.fromISO(value).toUTC().toISO() as string;
}

/*
 Returns true if given event is read-only
 */
export function isEventReadOnly(event: MbscScheduleEvent): boolean {
    return event.role !== "W";
}

/*
 Build a MbscScheduleEvent from the getEvents API response.
 */
export function buildFromScheduleEvent(event: ScheduleEvent, row: MbscScheduleResource): MbscScheduleEvent {
    const declinedEvent = event.attendees && event.attendees.filter(
        a => a.email === row.extId && a.status === "declined"
    ).length > 0;

    return {
        isNew: false,
        id: event.id,
        title: event.name,
        description: fixGoogleCalendarDescription(event.description),
        declinedEvent: declinedEvent,
        attendeesOmitted: event.attendeesOmitted,
        attendees: event.attendees,
        allDay: event.allDay,
        start: formatDates(event.start, event.allDay),
        end: formatDates(event.end, event.allDay),
        resource: row.id,
        location: event.location,
        canInviteGuests: event.canInviteGuests,
        link: event.link,
        role: event.role,
        color: event.bgColor,
        textColor: event.fgColor,
        organizer: event.organizer,
        outOfOffice: event.outOfOffice,
        workLocation: event.workLocation,
        sharedId: event.sharedId,
        editable: !event.workLocation,
    };
}

/*
 Build a MbscScheduleEvent from the getSharedEvents API response.
 */
export function buildFromSharedScheduleEvent(event: SharedScheduleEvent, row: MbscScheduleResource): MbscScheduleEvent {
    return {
        isNew: false,
        id: event.id,
        title: event.name,
        description: fixGoogleCalendarDescription(""), // TODO-return description
        declinedEvent: false,
        attendeesOmitted: false,
        attendees: [],
        allDay: event.allDay,
        start: formatDates(event.start, event.allDay),
        end: formatDates(event.end, event.allDay),
        resource: row.id,
        location: event.location,
        canInviteGuests: false,
        link: undefined,
        role: event.role,
        color: event.bgColor,
        textColor: event.fgColor,
        organizer: false,
        outOfOffice: event.outOfOffice,
        workLocation: event.workLocation,
        sharedId: event.id,
        editable: !event.workLocation,
    };
}

/*
 Build a ScheduleEvent object for use by the createEvent API.
 */
export function buildCreateScheduleEvent(event: MbscScheduleEvent): AddScheduleRowEvent {
    return {
        name: event.title || "",
        description: event.description,
        start: event.start as string,
        end: event.end as string,
        allDay: event.allDay || false,
        location: event.location || "",
        attendees: event.attendees ? event.attendees.map(a => a.email) : undefined,
    };
}

/*
 Build a ScheduleEvent object for use by the updateEvent API.
 Only include properties which changed (patch-update).
 */
export function buildUpdateScheduleEvent(event: MbscScheduleEvent, changedProps: string [], newResourceId: string | undefined): UpdateScheduleRowEvent {
    return Object.fromEntries(changedProps.map((key) => {
        switch (key) {
            case "title": {
                return ["name", event[key]];
            }
            case "color": {
                return ["bgColor", event[key]];
            }
            case "resource": {
                if (newResourceId) {
                    return ["destRowId", newResourceId];
                } else {
                    return [];
                }
            }
            case "attendees": {
                return ["attendees", event.attendees ? event.attendees.map(a => a.email) : undefined]
            }
            default: {
                return [key, event[key]];
            }
        }
    })) as UpdateScheduleRowEvent
}
