// Source: https://github.com/visualjerk/quill-magic-url
import Quill from "quill/core";
import Delta from "quill-delta";
import * as Parchment from "parchment";

interface QuillNode extends Node {
    data: any
}

class QuillLeafBlot extends Parchment.LeafBlot {
    text?: string
}

const defaults = {
    globalRegularExpression: /(https?:\/\/|www\.)\S+/gi,
    urlRegularExpression: /(https?:\/\/\S+)|(www\.\S+\.\S{2,10})|([a-z][a-z\d-]*?\.(?:com|org|net))/i,
};

export default class MagicUrl {
    quill: Quill
    options: any

    constructor(quill: Quill) {
        this.quill = quill;
        this.options = defaults;
        this.registerTypeListener();
        this.registerPasteListener();
    }

    registerPasteListener() {
        this.quill.clipboard.addMatcher(Node.TEXT_NODE, (node: Node, delta: Delta) => {
            if (typeof (node as QuillNode).data !== 'string') {
                return delta;
            }
            const matches = ((node as QuillNode).data as string).match(this.options.globalRegularExpression);
            if (matches && matches.length > 0) {
                const newDelta = new Delta();
                let str = (node as QuillNode).data;
                matches.forEach(match => {
                    const split = str.split(match);
                    const beforeLink = split.shift();
                    newDelta.insert(beforeLink);
                    newDelta.insert(match, {link: this.normalize(match)});
                    str = split.join(match);
                });
                newDelta.insert(str);
                delta.ops = newDelta.ops;
            }
            return delta;
        });
    }

    registerTypeListener() {
        this.quill.on('text-change', (delta: Delta) => {
            let ops = delta.ops;
            // Only return true, if last operation includes whitespace inserts
            // Equivalent to listening for enter, tab or space
            if (!ops || ops.length < 1 || ops.length > 2) {
                return;
            }
            let lastOp = ops[ops.length - 1];
            if (!lastOp.insert || typeof lastOp.insert !== 'string' || !lastOp.insert.match(/\s/)) {
                return;
            }
            this.checkTextForUrl();
        });
    }

    checkTextForUrl() {
        let sel = this.quill.getSelection();
        if (!sel) {
            return;
        }
        const leafs = this.quill.getLeaf(sel.index);
        let leaf = leafs[0] as QuillLeafBlot;
        if (!leaf || !leaf.text || leaf.parent.domNode.localName === "a") {
            return;
        }
        let urlMatch = leaf.text.match(this.options.urlRegularExpression);
        if (!urlMatch || urlMatch.index === undefined) {
            return;
        }
        let leafIndex = this.quill.getIndex(leaf);
        let index = leafIndex + urlMatch.index;

        this.textToUrl(index, urlMatch[0]);
    }

    textToUrl(index: number, url: string) {
        const ops = new Delta()
            .retain(index)
            .delete(url.length)
            .insert(url, {link: this.normalize(url)});
        this.quill.updateContents(ops);
    }

    normalize(url: string) {
        return url;
    }
}
