import * as React from "react";
import {useState} from "react";
import Popover from "@mui/material/Popover";
import {MbscScheduleResource} from "../../utils/utils";
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Tooltip
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import Button from "@mui/material/Button";

export function ResourceFilter({resources, onFilterChanged}: {
    resources: MbscScheduleResource[],
    onFilterChanged: (filteredResourceIds: string[]) => void
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchText, setSearchText] = useState("");
    const open = Boolean(anchorEl);

    const handleFilterOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleResource = (resource: MbscScheduleResource) => {
        resource.visible = !resource.visible;
        onFilterChanged(resources.filter(r => r.visible).map(r => r.id as string));
    }

    const handleShowAll = () => {
        onFilterChanged(resources.map(r => r.id as string));
    }

    const handleHideAll = () => {
        onFilterChanged([]);
    }

    const searchResultResources = resources.filter(resource => {
        return (resource.name || "").toLowerCase().includes(searchText.toLowerCase());
    });

    const hasFilteredResource = resources.filter(r => !r.visible).length > 0;

    return (
        <Box>
            <Tooltip title={hasFilteredResource ? "Manage filtered rows" : "Filter rows"}>
                <IconButton
                    color="secondary"
                    aria-label="Filter rows"
                    sx={{padding: 0}}
                    onClick={handleFilterOpenClick}
                >
                    {hasFilteredResource ? <FilterListOffIcon/> : <FilterListIcon/>}
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{p: 2}}>
                    <TextField
                        variant="outlined"
                        aria-label="Search by name"
                        label="Search by name"
                        value={searchText}
                        size="small"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchText(e.target.value);
                        }}
                    />
                    <List sx={{width: "100%", minHeight: 200, maxHeight: 350, overflowY: "auto"}}>
                        {searchResultResources.map(resource => {
                            return (
                                <ListItem
                                    key={resource.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={() => handleToggleResource(resource)}
                                            checked={resource.visible}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={() => handleToggleResource(resource)}>
                                        <ListItemText>
                                            {resource.name}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Divider sx={{mt: 1, mb: 1}}/>
                    <Box display="flex" justifyContent="space-between">
                        <Button aria-label="Show all" onClick={handleShowAll}>Show all</Button>
                        <Button aria-label="Hide all" onClick={handleHideAll}>Hide all</Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
}
