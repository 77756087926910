declare global {
    interface Window {
        gaId: string;
        gaAnalytics: {
            name: string;
            method: string;
        }
    }

    function gtag(type: string, data: object | string, parameters?: object): void;
}

const urlPrefix = '/app'

function gaAvailable(): boolean {
    return window.gaId !== "" && window.gaId !== "None";
}

export function gaInit(userId?: string) {
    if (userId && gaAvailable()) {
        gtag("set", {"user_id": userId});
    }
}

export function gaEvent(name: string, parameters: any) {
    if (gaAvailable()) {
        gtag("event", name, parameters);
    }
}

export function gaPageView() {
    if (gaAvailable()) {
        const pagePath = urlPrefix + window.location.pathname;
        gtag("set", {"page_path": pagePath});
    }
}
