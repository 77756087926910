import * as React from "react";
import {useEffect} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {DialogProps} from "@toolpad/core";

export function EventNameFilterDialog({payload, open, onClose}: DialogProps<string | undefined, string | undefined>) {
    const [name, setName] = React.useState<string>(payload || "");
    const filterNameRef = React.useRef<HTMLInputElement | null>(null);

    const handleCancel = async () => {
        await onClose(undefined);
    }

    const handleSetFilter = async () => {
        await onClose(name);
    }

    useEffect(() => {
        // Somehow doesn't work without a timeout
        const timeout = setTimeout(() => {
            if (filterNameRef.current) {
                filterNameRef.current.focus();
            }
        });

        return () => clearTimeout(timeout);
    }, []);

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <DialogTitle>
                Filter events by name
            </DialogTitle>
            <DialogContent>
                <TextField
                    hiddenLabel
                    inputRef={filterNameRef}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth={true}
                    size="small"
                    value={name}
                    placeholder="Enter one or more event names"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                        if (e.key === "Enter" || e.key === "NumpadEnter") {
                            await handleSetFilter();
                        }
                    }}
                >
                </TextField>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleCancel} color="secondary">Cancel</Button>
                    <Button onClick={handleSetFilter} variant="contained" sx={{ml: 1}}>Enable</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
