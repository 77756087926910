import * as React from "react";
import {CalendarResultWithDisplayName} from "../tab-from-google-calendar/from-google-calendar";
import {CalendarEmailAutocomplete} from "../../edit-event-dialog/tab-guests/calendar-email-autocomplete";
import {Box, Stack, Typography} from "@mui/material";
import {useState} from "react";
import Button from "@mui/material/Button";

export function FromEmail({onCalendarAdded}: {
    onCalendarAdded: (calendar: CalendarResultWithDisplayName) => void
}) {
    const [calendarEmail, setCalendarEmail] = useState<string>("");

    return (
        <Box height={350}>
            <Stack direction="row" spacing={2} sx={{alignItems: "center", mb: 2}}>
                <CalendarEmailAutocomplete
                    label="Email address or contact name"
                    inputValue={calendarEmail}
                    onInputValueChanged={(email: string) => {
                        setCalendarEmail(email)
                    }}
                />
                <Button
                    variant="text"
                    color="primary"
                    aria-label="Add calendar from email"
                    disabled={!calendarEmail}
                    onClick={() => {
                        onCalendarAdded({
                           id: calendarEmail,
                           name: calendarEmail,
                           displayName: calendarEmail,
                           type: "G",
                        });
                    }}
                >Add</Button>
            </Stack>
            <Typography variant="caption">
                Make sure your Google user has permission to access the selected Google Calendar. <a
                href="https://www.teamcalapp.com/scheduling-with-google-calendar-and-teamcal/#addcalendar"
                target="_blank" rel="noreferrer">Help</a>.
            </Typography>
        </Box>
    );
}
