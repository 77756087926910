import * as React from "react";
import {Outlet, useLocation} from "react-router";
import {Box, CssBaseline} from "@mui/material";
import {useEffect} from "react";
import {AuthProvider} from "../auth/auth-provider";
import {gaPageView} from "../clients/google-analytics";

export function Root() {
    const location = useLocation();

    useEffect(() => {
        document.title = "TeamCal App";
        gaPageView();
    }, [location]);

    return (
        <Box>
            <CssBaseline/>
            <AuthProvider>
                <Outlet />
            </AuthProvider>
        </Box>
    );
}
