import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useAuth} from "../../auth/auth-provider";
import {GoToPurchaseScreen} from "../../utils/purchase-button";
import {useLocation, useNavigate} from "react-router";

export function TrialExpiredPopup() {
    const {authContext} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const isTrialExpired = authContext?.account?.features.length === 0;

    const handleTrialUpgrade = () => {
        GoToPurchaseScreen(navigate, location);
    }

    return (
        <Dialog
            fullWidth
            open={isTrialExpired}
            disableEscapeKeyDown={true}
        >
            <DialogContent>
                <Stack direction="column"
                       spacing={4}
                       sx={{display: "flex", alignItems: "center", mt: 2, mb: 2}}>
                    <Typography variant="h5">Your trial has expired</Typography>
                    <Typography variant="body1">To continue using TeamCal, click "Buy TeamCal" to purchase a
                        subscription.</Typography>
                    <Stack direction="row" spacing={2}>
                        <Button startIcon={<ShoppingCartIcon/>}
                                onClick={handleTrialUpgrade}
                                variant="contained">
                            Buy TeamCal
                        </Button>
                        <Button onClick={handleTrialUpgrade}>
                            Learn more
                        </Button>
                    </Stack>
                    <ul style={{listStyle: "none"}}>
                        <li>⭐ Pick up where you left off - all your data is still there.</li>
                        <li style={{marginTop: 8}}>⭐ Anyone you've invited during the trial still has access.</li>
                        <li style={{marginTop: 8}}>⭐ <b>Get two months free</b> every year by subscribing to
                            our<br/> annual subscription.
                        </li>
                    </ul>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
