export async function batchPromiseAll<T>(promises: Promise<T>[], batchSize: number): Promise<T[]> {
  let results: T[] = [];

  for (let i = 0; i < promises.length; i += batchSize) {
    const batch = promises.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch);
    results = [...results, ...batchResults];
  }

  return results;
}
