import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ScheduleInfoWithActions} from "./schedules-view";

export function ScheduleActions({info}: {info: ScheduleInfoWithActions }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleIsPrivate = () => {
        handleMenuClose();
        info.toggleIsPrivate();
    }

    const handleDelete =() => {
        handleMenuClose();
        info.deleteSchedule();
    }

    return <Box>
        <Tooltip title="Manage schedule">
            <IconButton
                id="schedule-action-button"
                aria-label="Manage schedule"
                aria-controls={open ? 'schedule-action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuOpen}
            >
                <MoreVertIcon/>
            </IconButton>
        </Tooltip>
        <Menu
            id="schedule-action-menu"
            aria-labelledby="schedule-action-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={handleToggleIsPrivate}>
                <ListItemIcon>
                    {info.isPrivate ? <PublicIcon fontSize="small"/> : <LockIcon fontSize="small"/>}
                </ListItemIcon>
                <ListItemText>{info.isPrivate ? "Share with users" : "Make schedule private"}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Delete Schedule</ListItemText>
            </MenuItem>
        </Menu>
    </Box>;
}
