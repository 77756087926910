import {createTheme} from '@mui/material/styles';
import {indigo} from '@mui/material/colors';
import {PaletteColorOptions} from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

declare module '@mui/material/styles' {
  interface CustomPalette {
    alternate: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    alternate: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    alternate: true;
  }
}

export const TeamCalTheme = createTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: "#212121",
    },
    alternate: createColor("#00a8f4"),
  },
});
