import * as React from "react";
import {Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {ViewTimeframeEnum, ViewZoomEnum} from "../../../../clients/teamcal-api";
import {useSchedule} from "../../schedule-provider";

type ViewTimeframe = {
    key: ViewTimeframeEnum;
    type: string;
    size: number;
    label: string;
    defaultZoom: ViewZoomEnum;
    zoomLevels: Set<string>;
}

export const ViewTimeframes: ViewTimeframe[] = [
    {key: "D1", type: "day", size: 1, label: "1 Day", defaultZoom: "hour", zoomLevels: new Set(["hour"])},
    {key: "D4", type: "day", size: 4, label: "4 Days", defaultZoom: "day", zoomLevels: new Set(["hour", "day"])},
    {key: "D10", type: "day", size: 10, label: "10 Days", defaultZoom: "day", zoomLevels: new Set(["hour", "day"])},
    {key: "W1", type: "day", size: 7, label: "Week", defaultZoom: "day", zoomLevels: new Set(["hour", "day"])},
    {key: "W2", type: "day", size: 14, label: "2 Weeks", defaultZoom: "day", zoomLevels: new Set(["hour", "day"])},
    {key: "M1", type: "day", size: 30, label: "Month", defaultZoom: "day", zoomLevels: new Set(["hour", "day", "week"])},
    {key: "M3", type: "day", size: 90, label: "3 Months", defaultZoom: "week", zoomLevels: new Set(["hour", "day", "week", "month"])},
    {key: "M12", type: "month", size: 12, label: "Year", defaultZoom: "month", zoomLevels: new Set(["hour", "day", "week", "month"])},
]

export const ViewColumnWidths: Record<string, string> = {
    "hour": "xsmall",
    "day": "small",
    "week": "small",
    "month": "small",
}

const LegacyViewScaleMapping: Record<string, string> = {
    "D": "D1",
    "M": "M1",
    "WF": "W1",
    "W": "W1",
    "3M": "M3",
    "WW": "W1",
    "WWF": "W1",
    "2W": "W2",
    "Y": "M12",
}

export function mapLegacyViewScale(viewScale: string): ViewTimeframe {
    const viewTimeframe = ViewTimeframes.find(v => v.key === LegacyViewScaleMapping[viewScale]);
    return viewTimeframe ? viewTimeframe : ViewTimeframes[0];
}

export function ViewTimeframeSelector() {
    const schedule = useSchedule();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (timeframe: string) => {
        handleClose();

        let changes = [{prop: "viewTimeframe", value: timeframe}];
        const viewTimeframe = ViewTimeframes.find(v => v.key === timeframe);
        if (viewTimeframe) {
            changes = [...changes, {prop: "viewZoom", value: viewTimeframe!.defaultZoom}];
        }

        schedule.applyChanges(changes);
    }

    return (
        <Box>
            <Tooltip title="Select view timeframe">
                <Button color="secondary" onClick={handleClick} endIcon={<ArrowDropDownIcon/>}>
                    {(ViewTimeframes.find(z => z.key === schedule.data.viewTimeframe)?.label)}
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {ViewTimeframes.map((size, index) => {
                    return (
                        <MenuItem key={size.key} divider={index === 4} onClick={() => handleChange(size.key)}>
                            {schedule.data.viewTimeframe === size.key &&
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                            }
                            <ListItemText inset={schedule.data.viewTimeframe !== size.key}>{size.label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
}
