import Button from "@mui/material/Button";
import * as React from "react";
import {getCookie} from "../../utils/cookie";

export function LogoutButton() {
    const crsfToken = getCookie("csrftoken");

    return (
        <form name="logoutForm" method="post" action="/logout">
            <input name="csrfmiddlewaretoken" type="hidden" value={crsfToken}/>
            <Button sx={{color: "primary.contrastText"}} color="secondary" type="submit">Logout</Button>
        </form>
    );
}
