import * as React from "react";
import {ReactElement, useState} from "react";
import {Box, IconButton, Paper, Stack, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {ColorSelector, RowColors} from "../utils/color-selector";
import {MbscScheduleResource, RowOrderDirections} from "../../utils/utils";
import {RowOrderSelector} from "./row-order-selector";
import Button from "@mui/material/Button";

export type ResourceHooverMenuProp = {
    resource: MbscScheduleResource,
    sortActive: boolean,
    onChangeRowOrder: (resource: MbscScheduleResource, dir: RowOrderDirections) => Promise<void>,
    onSetBeforeRowOrder: (resource: MbscScheduleResource) => Promise<void>,
    onCancelRowOrderChange: () => void,
    onRenameRow: (resource: MbscScheduleResource) => Promise<void>,
    onRemoveRow: (resource: MbscScheduleResource) => Promise<void>,
    onSetRowColor: (color: string, resource: MbscScheduleResource) => Promise<void>,
}

type ResourceHooverMenuPropWithChildren = ResourceHooverMenuProp & { children?: ReactElement };

export function ResourceHooverMenu({
                                       resource,
                                       sortActive,
                                       onChangeRowOrder,
                                       onSetBeforeRowOrder,
                                       onCancelRowOrderChange,
                                       onRenameRow,
                                       onRemoveRow,
                                       onSetRowColor,
                                       children
                                   }: ResourceHooverMenuPropWithChildren) {
    const [show, setShow] = useState(false);
    const autoHideTimeout = 50;

    const handleChangeRowOrder = async (dir: RowOrderDirections) => {
        setShow(false);
        await onChangeRowOrder(resource, dir);
    };

    const handleCancelRowOrderChange = () => {
        setShow(false);
        onCancelRowOrderChange();
    }

    const handleMoveBefore = async () => {
        setShow(false);
        await onSetBeforeRowOrder(resource);
    };

    const handleRenameRow = async () => {
        setShow(false);
        await onRenameRow(resource);
    };

    const handleRemoveRow = async () => {
        setShow(false);
        await onRemoveRow(resource);
    };

    const handleSetRowColor = async (color: string) => {
        setShow(false);
        await onSetRowColor(color, resource);
    }

    return (
        <Box
            sx={{height: "100%", width: 200, display: "grid"}}
            onMouseEnter={() => {
                setShow(true);
            }}
            onMouseLeave={() => {
                setTimeout(() => {
                    setShow(false);
                }, autoHideTimeout);
            }}
        >
            <Box sx={{gridArea: "1/1", width: 200, overflow: "hidden"}}>
                {children}
            </Box>
            {show &&
                <Box
                    className="tc-hidden-print"
                    sx={{
                    gridArea: "1/1",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mr: 1,
                }}
                >
                    <Paper elevation={1} sx={{p: 0.5}}>
                        {sortActive ? (
                            <Stack spacing={0.5} direction="row">
                                <Button size="small" onClick={handleMoveBefore}>Move here</Button>
                                <Button size="small" color="secondary" onClick={handleCancelRowOrderChange}>Cancel</Button>
                            </Stack>
                        ) : (
                            <Stack spacing={0.5} direction="row">
                                <RowOrderSelector onChangeRowOrder={handleChangeRowOrder}/>
                                <Tooltip title="Rename row">
                                    <IconButton size="small" onClick={handleRenameRow}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove row">
                                    <IconButton size="small" onClick={handleRemoveRow}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                                <ColorSelector
                                    tooltipTitle="Set row color"
                                    availableColors={RowColors}
                                    currentColor={resource.color as string}
                                    onChange={handleSetRowColor}
                                    onClose={async () => {
                                        setShow(false)
                                    }}
                                />
                            </Stack>
                        )}
                    </Paper>
                </Box>
            }
        </Box>
    );
}
