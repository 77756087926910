import * as React from "react";
import {AppBar, Box, Container, Fab, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import {createSchedule, updateUserSettings} from "../../clients/teamcal-api";
import AddIcon from "@mui/icons-material/Add";
import ViewList from "@mui/icons-material/ViewList";
import {useAuth} from "../../auth/auth-provider";
import {SchedulesView, ViewMode} from "./schedules-view";
import {useLocation, useNavigate} from "react-router";
import {useNotifications} from "@toolpad/core";
import {showNonResourceAPIErrorNotification} from "../../clients/teamcal-api-notifications";
import Button from "@mui/material/Button";
import {PurchaseButton} from "../../utils/purchase-button";

export function SchedulesRoute() {
    const {authContext, setAuthContext} = useAuth()
    const navigate = useNavigate();
    const notifications = useNotifications();
    const location = useLocation();

    const toggleViewMode = async () => {
        if (authContext?.account) {
            const currentMode = authContext.account.userSettings.schedulesViewMode;
            const mode = currentMode === "C" ? "L" : "C";

            authContext.account.userSettings.schedulesViewMode = mode;
            setAuthContext({...authContext})

            await updateUserSettings({schedulesViewMode: mode});
        }
    }

    const createNewSchedule = async () => {
        try {
            const schedule = await createSchedule({name: undefined})
            navigate(`/schedules/${schedule.id}`);
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                    <Typography variant="h6">TeamCal</Typography>
                    <span style={{flexGrow: 1}}></span>
                    <PurchaseButton />
                    <Button sx={{color: "primary.contrastText"}} color="secondary" onClick={() => {
                        navigate(`/account/`, {state: {"pathname": location.pathname}});
                    }}>Account</Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Typography variant="h4" sx={{mt: 2, mb: 2, flexGrow: 1}}>Schedules</Typography>
                    <Tooltip title="Switch to list view">
                        <IconButton onClick={toggleViewMode} sx={{mr: 1}} color="secondary"
                                    aria-label="Switch to list view">
                            <ViewList/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add new schedule">
                        <Fab color="alternate"
                             variant="extended"
                             aria-label="Add new schedule"
                             onClick={createNewSchedule}
                        >
                            <AddIcon/>
                            New
                        </Fab>
                    </Tooltip>
                </Box>
                <SchedulesView viewMode={ViewMode[authContext?.account?.userSettings.schedulesViewMode || "C"]}/>
            </Container>
        </Box>
    );
}
