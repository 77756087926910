import * as React from "react";
import {useRouteError} from "react-router";
import * as Sentry from "@sentry/react";
import {Box, Typography} from "@mui/material";

export function ErrorBoundary() {
    return (
        <Box sx={{p: 1}}>
            <Typography variant="body1">
                An unexpected error occurred.
                <br/>Please contact TeamCal at <a href="mailto:info@teamcalapp.com">info@teamcalapp.com</a> if the error persists.
            </Typography>
        </Box>
    );
}

export function TeamCalErrorBoundary() {
    const error = useRouteError() as Error;
    const [sentryId, setSentryId] = React.useState<string | null>(null);

    React.useEffect(() => {
        const id = Sentry.captureException(error, {
            tags: {
                tcSource: "Global Error Boundary",
            }
        });
        setSentryId(id);
    }, [error]);

    return (
        <div style={{fontFamily: "sans-serif"}}>
            <h1>TeamCal Error</h1>
            <span>An unexpected error occurred. Please contact TeamCal at <a href="mailto:info@teamcalapp.com">info@teamcalapp.com</a></span>
            <div style={{marginTop: "24px"}}><i>Sentry ID: {sentryId}</i></div>
        </div>
    );
}
