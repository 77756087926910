import * as React from "react";
import {Box, Typography} from "@mui/material";

export function ResourceSidebarHeader() {
    return (
        <Box display="flex"
             alignItems="flex-end"
             justifyContent="center"
             sx={{height: "100%", width: "100%"}}>
            <Typography variant="body1">Total</Typography>
        </Box>
    );
}

export const MemoResourceSidebarHeader = React.memo(ResourceSidebarHeader);
