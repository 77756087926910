import * as React from "react";
import {Box} from "@mui/material";
import {ScheduleCard} from "./schedule-card";
import {ScheduleInfoWithActions} from "./schedules-view";

export function ScheduleCardView({schedules}: {schedules: ScheduleInfoWithActions[]}) {
    return <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", m: -1}}>
        {schedules.map(scheduleInfo => {
            return <ScheduleCard key={scheduleInfo.id} info={scheduleInfo}/>
        })}
    </Box>;
}
