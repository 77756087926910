import * as React from "react";
import {Box, Menu, MenuItem, Tooltip, ListItemText, ListItemIcon, IconButton, Chip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {useDialogs} from "@toolpad/core";
import {EventNameFilterDialog} from "../../event-name-filter-dialog/event-name-filter-dialog";
import {AllDaySettingsEnum, OutOfOfficeSettingsEnum} from "../../../../clients/teamcal-api";

export type EventFilters = {
    eventName?: string;
    allDayEvents: AllDaySettingsEnum | null;
    outOfOfficeEvents: OutOfOfficeSettingsEnum | null;
}

export function EventFilterSelector({filter, onChange}: {
    filter: EventFilters,
    onChange: (filterName: string, value?: string) => void
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dialogs = useDialogs();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterByName = async () => {
        handleClose();
        const value = await dialogs.open(EventNameFilterDialog, filter.eventName);
        if (value && value !== "") {
            onChange("eventName", value);
        } else {
            onChange("eventName", undefined);
        }
    }

    const handleRemoveFilterByName = () => {
        handleClose();
        onChange("eventName", undefined);
    }

    const handleAllDayEventsChange = (value: string) => {
        handleClose();
        onChange("allDaySettings", filter.allDayEvents === value ? "" : value);
    }

    const handleOutOfOfficeEventsChange = (value: string) => {
        handleClose();
        onChange("outOfOfficeSettings", filter.outOfOfficeEvents === value ? "" : value);
    }

    return (
        <Box>
            <Tooltip title="Filter events">
                <IconButton color="secondary" onClick={handleClick}>
                    <FilterAltIcon/>
                </IconButton>
            </Tooltip>
            {filter.eventName &&
                <Tooltip title="Disable event name filter">
                    <Chip label={filter.eventName}
                          sx={{maxWidth: 150, ml: 1}}
                          onDelete={handleRemoveFilterByName}/>
                </Tooltip>
            }
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem divider={true} onClick={handleFilterByName}>
                    <ListItemText inset>Filter events by name</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleAllDayEventsChange("only")}>
                    {filter.allDayEvents === "only" &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={filter.allDayEvents !== "only"}>Show all day events only</ListItemText>
                </MenuItem>
                <MenuItem divider={true} onClick={() => handleAllDayEventsChange("hide")}>
                    {filter.allDayEvents === "hide" &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={filter.allDayEvents !== "hide"}>Hide all day events</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleOutOfOfficeEventsChange("only")}>
                    {filter.outOfOfficeEvents === "only" &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={filter.outOfOfficeEvents !== "only"}>Show Out of Office events
                        only</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleOutOfOfficeEventsChange("hide")}>
                    {filter.outOfOfficeEvents === "hide" &&
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                    }
                    <ListItemText inset={filter.outOfOfficeEvents !== "hide"}>Hide Out of Office events</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
}
