import * as React from "react";
import {Box, Menu, MenuItem, Button, Tooltip, ListItemText, ListItemIcon} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {useSchedule} from "../../schedule-provider";
import {retrieveAvailableZoomLevels} from "../utils/zoom-utils";

type ZoomLevel = {
    key: string;
    label: string;
}

export const ZoomLevels: ZoomLevel[] = [
    {key: "hour", label: "Hourly"},
    {key: "day", label: "Daily"},
    {key: "week", label: "Weekly"},
    {key: "month", label: "Monthly"},
]

export function ZoomSelector() {
    const schedule = useSchedule();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (level: string) => {
        handleClose();
        schedule.applyChanges([{prop: "viewZoom", value: level}]);
    }

    let availableZoomLevels = retrieveAvailableZoomLevels(schedule.data.viewTimeframe);

    return (
        <Box>
            <Tooltip title="Select zoom level">
                <Button color="secondary" onClick={handleClick} startIcon={<ZoomInIcon/>}
                        endIcon={<ArrowDropDownIcon/>}>
                    {ZoomLevels.find(z => z.key === schedule.data.viewZoom)?.label}
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {ZoomLevels.map(level => {
                    return (
                        <MenuItem
                            key={level.key}
                            disabled={availableZoomLevels && availableZoomLevels.find(l => l.key === level.key) === undefined}
                            onClick={() => handleChange(level.key)}>
                            {schedule.data.viewZoom === level.key &&
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                            }
                            <ListItemText inset={schedule.data.viewZoom !== level.key}>{level.label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
}
