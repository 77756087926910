import * as React from "react";
import {useState} from "react";
import {DialogProps} from "@toolpad/core";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import {TabPanel} from "../../../utils/tab-panel";
import Button from "@mui/material/Button";
import {MbscScheduleResource} from "../utils/utils";
import {CalendarResultWithDisplayName, FromGoogleCalendar} from "./tab-from-google-calendar/from-google-calendar";
import {CalendarResult, TeamCalAPIError} from "../../../clients/teamcal-api";
import {FromEmail} from "./tab-from-email/from-email";

export type AddResourcePayload = {
    resources: MbscScheduleResource[],
    onResourceAdded: (calendar: CalendarResult) => Promise<unknown>
}

export function AddResourceDialog({payload, open, onClose}: DialogProps<AddResourcePayload>) {
    const [tabValue, setTabValue] = useState(0);
    const [resources, setResources] = useState(payload.resources);

    const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleResourceAdded = async (calendar: CalendarResultWithDisplayName) => {
        // Update resource state manually, because Dialog doesn't get
        // any resource updates from the scheduling component.
        setResources([
            ...resources,
            {
                id: calendar.id,
                extId: calendar.id,
                name: calendar.displayName,
            } as MbscScheduleResource
        ])

        const error = await payload.onResourceAdded(calendar);
        if (error) {
            // Reset list if resources couldn't be added
            setResources(resources);

            if (error instanceof TeamCalAPIError && error.details?.code === "ScheduleRowCountExceeded") {
                await handleClose();
            }
        }
    }

    const handleClose = async () => {
        await onClose();
    };

    return (
        <Dialog fullWidth sx={{minHeight: 528}} open={open} onClose={handleClose}>
            <DialogTitle bgcolor="primary.main" sx={{color: "primary.contrastText"}}>
                Add calendar to schedule
            </DialogTitle>
            <DialogContent>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="From Google Calendar list"/>
                        <Tab label="From email address"/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <FromGoogleCalendar resources={resources} onCalendarAdded={handleResourceAdded} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <FromEmail onCalendarAdded={handleResourceAdded} />
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Box sx={{width: "100%", pl: 2, pr: 2, pb: 2}} display="flex">
                    <span style={{flexGrow: 1}}></span>
                    <Button onClick={handleClose} color="secondary">Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
