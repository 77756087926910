import * as React from "react";
import {useParams} from "react-router";
import {SchedulingView} from "./scheduling-view";
import {Box} from "@mui/material";
import {ScheduleProvider} from "./schedule-provider";
import {SchedulingAppBar} from "./scheduling-app-bar";
import {LocaleProvider} from "./locale-provider";
import {TrialExpiredPopup} from "./trial-expired-popup";
import {SchedulingFooterBar} from "./scheduling-footer-bar";

export function ScheduleRoute() {
    const {scheduleId} = useParams();

    if (scheduleId) {
        return (
            <ScheduleProvider scheduleId={scheduleId}>
                <LocaleProvider>
                    <Box>
                        <SchedulingAppBar/>
                        <SchedulingView/>
                        <SchedulingFooterBar/>
                        <TrialExpiredPopup/>
                    </Box>
                </LocaleProvider>
            </ScheduleProvider>
        );
    } else {
        return <div>Error</div>;
    }
}
