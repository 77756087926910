import * as React from "react";
import {Link, Navigate, useSearchParams} from "react-router";
import {useAuth} from "../../auth/auth-provider";
import {AppBar, Box, Button, Link as MuiLink, Stack, Toolbar, Typography} from "@mui/material";

export function LoginIFrame() {
    /* Can be used if TeamCal is embedded into another iframe */

    const [searchParams] = useSearchParams();
    const forceNewAuthentication = (searchParams.get("force") || "false").toLowerCase() === "true";

    const {authContext} = useAuth()
    if (!forceNewAuthentication && authContext && authContext.isAuthenticated) {
        return <Navigate to="/"/>
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar sx={{display: "flex", flexDirection: "row"}}>
                    <MuiLink sx={{color: "primary.contrastText"}} variant="h6"
                             href="https://www.teamcalapp.com" target="_blank">TeamCal</MuiLink>
                    <span style={{flexGrow: 1}}></span>
                </Toolbar>
            </AppBar>
            <Box style={{background: "#b3e5fc", padding: "56px 16px", textAlign: "center"}}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h4">
                        Google Calendar’s missing schedule view
                    </Typography>
                </Stack>
            </Box>
            <Box sx={{textAlign: "center", mt: 10}}>
                <Button variant="contained" color="primary" size="large" component={Link} to="/login">Login</Button>
            </Box>
        </Box>
    );
}
