import * as React from "react";
import {Container, Grid2 as Grid, Stack, Switch, Typography} from "@mui/material";
import {useAuth} from "../../../auth/auth-provider";
import {updateAccountRestrictions} from "../../../clients/teamcal-api";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {useNotifications} from "@toolpad/core";

export function TabRestrictions() {
    const {authContext, setAuthContext} = useAuth();
    const notifications = useNotifications();
    const account = authContext?.account!;

    const updateRestriction = async (name: string, value: any) => {
        const currentContext = authContext!;

        try {
            // Update for immediate UI effect
            setAuthContext({
                ...authContext!,
                account: {
                    ...authContext!.account!,
                    restrictions: {
                        ...authContext!.account!.restrictions,
                        [name]: value,
                    }
                }
            })

            account.restrictions = await updateAccountRestrictions({[name]: value});

            // Overwrite current context with response
            setAuthContext({...authContext})
        } catch (error) {
            // Reset view
            setAuthContext({...currentContext})

            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    const handleAddRestriction = async () => {
        await updateRestriction("add", !account.restrictions?.add);
    }

    const handleEditRestriction = async () => {
        await updateRestriction("edit", !account.restrictions?.edit);
    }

    const handleMoveRestriction = async () => {
        await updateRestriction("move", !account.restrictions?.move);
    }

    const handleDeleteRestriction = async () => {
        await updateRestriction("delete", !account.restrictions?.delete);
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>Account restrictions</Typography>
            <Container disableGutters maxWidth="sm">
                <Grid container spacing={1}>
                    <Grid size={8} display="flex" alignItems="center">
                        <Typography variant="body1">Disable create new event</Typography>
                    </Grid>
                    <Grid size={4} display="flex" justifyContent="flex-end">
                        <Switch checked={account.restrictions?.add} onChange={handleAddRestriction}/>
                    </Grid>
                    <Grid size={8} display="flex" alignItems="center">
                        <Typography variant="body1">Disable edit event (content & resize)</Typography>
                    </Grid>
                    <Grid size={4} display="flex" justifyContent="flex-end">
                        <Switch checked={account.restrictions?.edit} onChange={handleEditRestriction}/>
                    </Grid>
                    <Grid size={8} display="flex" alignItems="center">
                        <Typography variant="body1">Disable move event between team members</Typography>
                    </Grid>
                    <Grid size={4} display="flex" justifyContent="flex-end">
                        <Switch checked={account.restrictions?.move} onChange={handleMoveRestriction}/>
                    </Grid>
                    <Grid size={8} display="flex" alignItems="center">
                        <Typography variant="body1">Disable delete event</Typography>
                    </Grid>
                    <Grid size={4} display="flex" justifyContent="flex-end">
                        <Switch checked={account.restrictions?.delete} onChange={handleDeleteRestriction}/>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
}
