import * as React from "react";
import {MbscScheduleEvent} from "../../utils/utils";
import {Box, Menu, MenuItem} from "@mui/material";

export enum CtxMenuActions {
    Add,
    Copy,
    Paste,
    Edit,
    Duplicate = 4,
}

export type CtxMenuAction = {
    type: CtxMenuActions;
    date: Date;
    event?: MbscScheduleEvent;
    resourceId: string;
}

export type CtxMenuEvent = {
    domEvent: PointerEvent;
    date: Date;
    event?: MbscScheduleEvent;
    resourceId: string;
}

type CtxMenuProps = {
    source: "event" | "resource";
    menuEvent?: CtxMenuEvent;
    allowAdd: boolean;
    allowEdit: boolean;
    onClose: () => void;
    onAction: (args: CtxMenuAction) => void;
}

export function ResourceCtxMenu({source, menuEvent, allowAdd, allowEdit, onClose, onAction}: CtxMenuProps) {
    const open = Boolean(
        menuEvent?.domEvent && (
            source === "event" && (allowAdd || allowEdit) ||
            source === "resource" && allowAdd
        )
    );

    const handleClose = () => {
        onClose();
    };

    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        onClose();
    }

    const handleMenuClick = (type: CtxMenuActions) => {
        onClose();
        if (menuEvent) {
            onAction({
                type: type,
                date: menuEvent?.date,
                event: menuEvent?.event,
                resourceId: menuEvent?.resourceId,
            })
        }
    }

    const eventTitle = (): string => {
        const title = menuEvent?.event?.title || "";
        return title.length > 10 ? `${title.substring(0, 10)}...` : title;
    }

    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={menuEvent?.domEvent ? {
                top: menuEvent?.domEvent.clientY, left: menuEvent?.domEvent.clientX
            } : undefined}
            open={open}
            onClose={handleClose}
            onContextMenu={handleRightClick}
        >
            {source === "event" && (
                <Box>
                    {allowEdit &&
                        <MenuItem onClick={() => handleMenuClick(CtxMenuActions.Edit)}>Edit</MenuItem>
                    }
                    {allowAdd &&
                        <MenuItem onClick={() => handleMenuClick(CtxMenuActions.Copy)}>Copy</MenuItem>
                    }
                    {allowAdd &&
                        <MenuItem onClick={() => handleMenuClick(CtxMenuActions.Duplicate)}>Duplicate</MenuItem>
                    }
                </Box>
            )}
            {source === "resource" && (
                <Box>
                    {allowAdd &&
                        <MenuItem onClick={() => handleMenuClick(CtxMenuActions.Add)}>Add event</MenuItem>
                    }
                    {allowAdd && menuEvent?.event &&
                        <MenuItem onClick={() => handleMenuClick(CtxMenuActions.Paste)}>
                            Paste "{eventTitle()}"
                        </MenuItem>
                    }
                </Box>
            )}
        </Menu>
    );
}
