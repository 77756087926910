import "./clients/sentry"; // Needs to be the first import
import * as React from "react";
import {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router";
import {routes} from "./routes";
import {DialogsProvider} from "@toolpad/core/useDialogs";
import {NotificationsProvider} from "@toolpad/core/useNotifications";
import {ThemeProvider} from "@mui/material";
import {TeamCalTheme} from "./theme";
import {gaEvent} from "./clients/google-analytics";

function TeamCalApp() {
    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
    const router = sentryCreateBrowserRouter(routes);

    useEffect(() => {
        gaEvent(window.gaAnalytics.name, {"method": window.gaAnalytics.method});
    }, []);

    return (
        <React.StrictMode>
            <ThemeProvider theme={TeamCalTheme}>
                <NotificationsProvider>
                    <DialogsProvider>
                        <RouterProvider router={router}/>
                    </DialogsProvider>
                </NotificationsProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
}

const root = createRoot(
    document.getElementById("app") as Element
);
root.render(<TeamCalApp/>);
