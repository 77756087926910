import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {Account, getSharedView, SharedViewInfo, TeamCalAPIError} from "../clients/teamcal-api";
import {components} from "../clients/teamcal-api-schema";
import {useParams} from "react-router";
import {AuthContext, AuthInfo} from "./auth-provider";
import {SharedScheduleTokenError} from "../routes/shared-schedule/shared-schedule-token-error";

export class SharedAuthInfo extends AuthInfo {
    sharedData?: SharedViewInfo;
    sharedToken?: string;
}

function buildAccountInfoFromSharedView(data: SharedViewInfo): Account {
    return {
        id: "",
        createdOn: "",
        intercomId: "",
        stripeKey: "",
        fullName: "",
        email: "",
        isOwner: false,
        userSettings: {
            timezone: data.timezone,
            showWeeks: true,
            weekStart: data.weekStart,
            dateOrder: data.dateOrder,
            time24h: data.time24h,
            schedulesViewMode: undefined,
        } as components["schemas"]["UserSettings"],
        restrictions: undefined,
        trialExpiration: "",
        sso: false,
        availablePlans: [],
        isOrgAccount: false,
        features: [],
        notifications: [],
    }
}

export function SharedAuthProvider({children}: { children: ReactElement }) {
    const [authContext, setAuthContext] = useState<AuthInfo>(new AuthInfo());
    const [tokenFailure, setTokenFailure] = useState<boolean>(false);
    const {token} = useParams();

    useEffect(() => {
        if (!token) {
            return;
        }

        let ignore = false;
        const newContext = new SharedAuthInfo();

        getSharedView(token).then(data => {
            if (!ignore) {
                newContext.isAuthenticated = true;
                newContext.sharedData = data;
                newContext.sharedToken = token;
                newContext.account = buildAccountInfoFromSharedView(data);
                setAuthContext(newContext);
            }
        }).catch(error => {
            newContext.isAuthenticated = false;
            newContext.sharedData = undefined;
            newContext.sharedToken = undefined;
            newContext.account = undefined;
            setAuthContext(newContext);
            setTokenFailure(true);

            if (!(error instanceof TeamCalAPIError) || error.details.code !== "SharingTokenInvalid") {
                throw error;
            }
        });

        return () => {
            ignore = true
        };
    }, [authContext.isAuthenticated, token]);

    if (tokenFailure) {
        return (
            <SharedScheduleTokenError/>
        );
    } else {
        return (
            <AuthContext.Provider value={{authContext, setAuthContext}}>{children}</AuthContext.Provider>
        );
    }
}
