import * as React from 'react';
import Popover from '@mui/material/Popover';
import Picker from "@emoji-mart/react";
import {IconButton} from "@mui/material";
import MoodIcon from '@mui/icons-material/Mood';

export type EmojiPickerSelect = {
    emoticons: string [];
    id: string;
    keywords: string [];
    name: string;
    native: string;
    shortcodes: string;
    unified: string;
}

export function EmojiPicker({onEmojiSelect, disabled}: {
    onEmojiSelect: (emoji: EmojiPickerSelect) => void,
    disabled?: boolean
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (emoji: EmojiPickerSelect) => {
        onEmojiSelect(emoji);
        handleClose();
    }

    const loadEmojiData = async () => {
        // noinspection SpellCheckingInspection
        const response = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data")
        return response.json()
    }

    const open = Boolean(anchorEl);
    const id = open ? 'emoji-picker-popover' : undefined;

    return (
        <div>
            <IconButton color="secondary" aria-describedby={id} onClick={handleClick} disabled={disabled}>
                <MoodIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Picker data={loadEmojiData} onEmojiSelect={handleSelect}/>
            </Popover>
        </div>
    );
}
