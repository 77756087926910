import {ViewTimeframes} from "../toolbar/view-timeframe-selector";
import {ZoomLevels} from "../toolbar/zoom-selector";

export function retrieveAvailableZoomLevels(timeframe?: string) {
    if (timeframe) {
        const viewTimeframe = ViewTimeframes.find(v => v.key === timeframe)
        if (viewTimeframe) {
            return ZoomLevels.filter(l => viewTimeframe.zoomLevels.has(l.key))
        }
    }

    return ZoomLevels;
}
