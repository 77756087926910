import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import DomToImage from "dom-to-image-more";
import {saveAs} from "file-saver";
import {Options} from "dom-to-image";

export type ImageData = {
    dataUrl: string;
    width: number;
    height: number;
}

interface OptionsMore extends Options {
    scale: number | undefined;
}

function initExport(instance: EventcalendarBase): Promise<void> {
    instance._el.classList.add("timeline-export");
    instance._print = true;

    return new Promise(resolve => {
        instance.forceUpdate(resolve);
    })
}

function cleanupExport(instance: EventcalendarBase): Promise<void> {
    instance._el.classList.remove("timeline-export");
    instance._print = false;

    return new Promise(resolve => {
        instance.forceUpdate(resolve);
    })
}

export async function GeneratePng(instance: EventcalendarBase): Promise<ImageData> {
    const element = instance._el;
    await initExport(instance);

    try {
        const width = element.scrollWidth;
        const height = element.scrollHeight;
        const dataUrl = await DomToImage.toPng(
            element,
            {
                width: width,
                height: height,
                scale: 2,
            } as OptionsMore);

        return {
            dataUrl: dataUrl,
            width: width,
            height: height,
        }
    } finally {
        await cleanupExport(instance);
    }
}

export async function Export2Png(instance: EventcalendarBase, name: string): Promise<void> {
    const {dataUrl} = await GeneratePng(instance);
    saveAs(dataUrl, `${name}.png`);
}

export async function GenerateJpeg(instance: EventcalendarBase): Promise<ImageData> {
    const element = instance._el;
    await initExport(instance);

    try {
        const width = element.scrollWidth;
        const height = element.scrollHeight;
        const dataUrl = await DomToImage.toJpeg(
            element,
            {
                width: width,
                height: height,
                quality: 0.85,
                scale: 2,
            } as OptionsMore);

        return {
            dataUrl: dataUrl,
            width: width,
            height: height,
        }
    } finally {
        await cleanupExport(instance);
    }
}

export async function Export2Jpeg(instance: EventcalendarBase, name: string): Promise<void> {
    const {dataUrl} = await GenerateJpeg(instance);
    saveAs(dataUrl, `${name}.jpg`);
}
