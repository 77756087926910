import * as React from "react";
import {useEffect} from "react";
import {
    Box,
    Chip,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import {configureSSO, deleteUser, getUsers, inviteUser, User} from "../../../clients/teamcal-api";
import {useDialogs, useNotifications} from "@toolpad/core";
import {showNonResourceAPIErrorNotification} from "../../../clients/teamcal-api-notifications";
import {InviteUserDialog} from "./invite-user-dialog";
import {DateTime} from "luxon";
import {ConfigureSSODialog} from "./configure-sso-dialog";
import {useAuth} from "../../../auth/auth-provider";
import {intercomSendEvent} from "../../../clients/intercom-api";

export function TabUsers() {
    const dialogs = useDialogs();
    const notifications = useNotifications();
    const {authContext, setAuthContext} = useAuth();
    const [users, setUsers] = React.useState<User[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const ssoEnabled = authContext?.account?.sso || false;

    useEffect(() => {
        let ignore = false;
        getUsers().then(users => {
            if (!ignore) {
                setUsers(users.sort((u1, u2) => u1.email.localeCompare(u2.email)));
            }
        }).finally(() => {
            setIsLoading(false);
        })

        return () => {
            ignore = true
        };
    }, []);

    const handleOpenInviteDialog = async () => {
        try {
            const invite = await inviteUser();
            intercomSendEvent("account-user-invite");
            await dialogs.open(InviteUserDialog, {
                expiresOn: DateTime.fromISO(invite.expiresOn),
                secret: invite.secret,
            });
        } catch (error) {
            showNonResourceAPIErrorNotification(error, notifications);
        }
    }

    const handleOpenSSODialog = async () => {
        const result = await dialogs.open(ConfigureSSODialog, {isEnabled: ssoEnabled});
        if (result !== undefined) {
            try {
                await configureSSO(result);

                setAuthContext({
                    ...authContext,
                    account: {
                        ...authContext!.account!,
                        sso: result,
                    }
                })

                if (result) {
                    notifications.show("Single Sign-On (SSO) enabled", {severity: "success", autoHideDuration: 3000});
                    intercomSendEvent("account-sso-enable");
                } else {
                    notifications.show("Single Sign-On (SSO) disabled", {severity: "success", autoHideDuration: 3000});
                    intercomSendEvent("account-sso-disable");
                }
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        }
    }

    const handleDeleteUser = async (email: string) => {
        const result = await dialogs.confirm(
            `Confirm to delete user "${email}".`,
            {
                title: "Delete user",
                okText: "Delete",
                cancelText: "Cancel",
            }
        );

        if (result) {
            try {
                await deleteUser(email);
                setUsers(users => users.filter(u => u.email !== email));
                intercomSendEvent("account-user-delete", {"email": email});
            } catch (error) {
                showNonResourceAPIErrorNotification(error, notifications);
            }
        }
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>User management</Typography>
            <Container disableGutters maxWidth="sm">
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <Button
                            onClick={handleOpenInviteDialog}
                            variant="contained"
                            endIcon={<PersonAddIcon/>}
                        >
                            Invite
                        </Button>
                        <Button onClick={handleOpenSSODialog}>
                            {ssoEnabled ? "Disable" : "Enable"} Single Sign-On (SSO)
                        </Button>
                    </Stack>
                    <Divider/>
                    {isLoading &&
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress/>
                        </Box>
                    }
                    <List sx={{width: "100%", maxHeight: 350, overflowY: "auto"}}>
                        {users.map(user => {
                            return (
                                <ListItem
                                    key={user.email}
                                    secondaryAction={
                                        <Box>
                                            {user.isOwner ? (
                                                <Chip size="small" label="Owner"/>
                                            ) : (
                                                <Tooltip title="Delete user">
                                                    <IconButton color="secondary" onClick={() => handleDeleteUser(user.email)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    }
                                >
                                    <ListItemText>{user.email}</ListItemText>
                                </ListItem>
                            );
                        })}
                    </List>
                </Stack>
            </Container>
        </Stack>
    );
}