import * as React from "react";
import {Button, Paper, Stack, Switch, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useAuth} from "../../../auth/auth-provider";

export type SubscriptionPlan = {
    title: string;
    plan: string;
    pricing: Record<number, number>;
    features: string[];
}

const MONTHLY = 1
const YEARLY = 12

const PLANS = [
    {
        title: "Starter",
        plan: "starter",
        pricing: {
            [MONTHLY]: 29,
            [YEARLY]: 290,
        },
        features: ["Manage 15 calendars", "Unlimited TeamCal users", "Unlimited schedules", "Export schedule"],
    },
    {
        title: "Premium",
        plan: "premium",
        pricing: {
            [MONTHLY]: 69,
            [YEARLY]: 690,
        },
        features: ["Manage 45 calendars", "Unlimited TeamCal users", "Unlimited schedules", "Export schedule", "Sharing & TV mode"],
    },
    {
        title: "Business",
        plan: "business",
        pricing: {
            [MONTHLY]: 149,
            [YEARLY]: 1490,
        },
        features: ["Manage 100 calendars", "Unlimited TeamCal users", "Unlimited schedules", "Export schedule", "Sharing & TV mode", "User permissions"],
    }
] as SubscriptionPlan[];

export function PricingTable({onSubscribe}: { onSubscribe: (plan: SubscriptionPlan, interval: number) => void }) {
    const {authContext} = useAuth();
    const [planInterval, setPlanInterval] = React.useState<number>(MONTHLY);

    const handleIntervalChange = () => {
        setPlanInterval(planInterval === MONTHLY ? YEARLY : MONTHLY);
    }

    const handleSubscribe = (plan: SubscriptionPlan) => {
        onSubscribe(plan, planInterval);
    }

    return (
        <Stack direction="column" spacing={4} sx={{display: "flex", alignItems: "center"}}>
            <Stack spacing={2} direction="row" sx={{display: "flex", alignItems: "center"}}>
                <Typography fontWeight="bold">Monthly</Typography>
                <Switch checked={planInterval === YEARLY} onChange={handleIntervalChange}/>
                <Typography fontWeight="bold">Yearly (save 2 months)</Typography>
            </Stack>
            <Stack direction="row" spacing={4}>
                {PLANS.filter(p => planInterval in p.pricing).map((p, i) => {
                    const planAvailable = authContext?.account?.availablePlans.find(ap => ap === p.plan) !== undefined;
                    const price = p.pricing[planInterval] / planInterval;
                    const formattedPrice = (price).toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: planInterval === MONTHLY ? 0 : 2,
                        maximumFractionDigits: 2,
                    });

                    return (
                        <Paper key={i} elevation={i === 1 ? 3 : 0} sx={{pl: 4, pr: 4, pt: 2, pb: 2}}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h6">{p.title}</Typography>
                                <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "center"}}>
                                    <Typography variant="h4">{formattedPrice}</Typography>
                                    <Typography variant="caption" lineHeight="1">per<br/>month</Typography>
                                </Stack>
                                {planInterval !== MONTHLY &&
                                    <Typography variant="caption"
                                                sx={{mt: "0 !important"}}>
                                        {(p.pricing[planInterval]).toLocaleString(undefined, {
                                            style: "currency",
                                            currency: "USD",
                                            minimumFractionDigits: 0
                                        })} billed annually
                                    </Typography>
                                }
                                <Button
                                    variant="contained"
                                    disabled={!planAvailable}
                                    onClick={() => handleSubscribe(p)}>
                                    Subscribe
                                </Button>
                                <Typography variant="body2">This includes:</Typography>
                                {p.features.map(f => {
                                    return (
                                        <Typography key={f} variant="body2"
                                                    sx={{display: "flex", alignItems: "center"}}>
                                            <CheckCircleIcon sx={{fontSize: 12, color: "#8c8c8c", mr: 1}}/>
                                            {f}
                                        </Typography>
                                    );
                                })}
                                {!planAvailable && (
                                    <Typography variant="caption" color="warning">
                                        You have more calendars<br/>than this plan supports.
                                    </Typography>
                                )}
                            </Stack>
                        </Paper>
                    );
                })}
            </Stack>
        </Stack>
    );
}
