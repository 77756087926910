import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";
import {useEffect, useRef, useState} from "react";
import MagicUrl from "./quill.magic.url";
import Quill from "quill";
import Link from 'quill/formats/link';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

const urlSchemaRegex = /^(?:[a-z0-9.\-+]+?:)?\/\//i

Link.sanitize = function (url: string) {
    // Force absolute URLs
    if (!url.match(urlSchemaRegex)) {
        return `https://${url}`;
    } else {
        return url;
    }
};

Quill.register("modules/magicUrl", MagicUrl, true);

function quillSetHtml(quill: Quill, value: string) {
    quill.setContents([{insert: '\n'}]);
    quill.clipboard.dangerouslyPasteHTML(0, value);
}

function quillGetHtml(quill: Quill) {
    const deltaOps = quill.getContents().ops || [];
    const converter = new QuillDeltaToHtmlConverter(deltaOps, {
        paragraphTag: '',
        linkTarget: '',
        encodeHtml: false
    });
    const html = converter.convert();

    // Check if text contains HTML tags other than line break tags (<br>)
    // if not, return as pure text in where all line break tags got replaced.
    // That's because some devices like IPhone can't render HTML in event descriptions
    // so let's not use HTML if not necessary.
    const text = html.replaceAll("<br/>", "\n");
    if (text.search(/<[a-z]/) >= 0) {
        return html;
    } else {
        return text;
    }
}

export function RichEdit(
    {content, onUpdate, disabled}: {
        content: string,
        onUpdate(html: string | undefined): void
        disabled?: boolean,
    }
) {
    const quillInitialized = useRef(false);
    const [quillEditor, setQuillEditor] = useState<Quill | undefined>(undefined);
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const boundsContainer = useRef<HTMLDivElement | null>(null);
    const editContainer = useRef<HTMLDivElement | null>(null);
    const toolbarRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Quill doesn't have a "cleanup/destroy" function. Use ref to prevent duplicate init during DEV.
        if (quillInitialized.current || !editContainer.current || !boundsContainer.current) {
            return;
        }

        const quill = new Quill(editContainer.current, {
            modules: {
                toolbar: toolbarRef.current,
                magicUrl: true,
            },
            bounds: boundsContainer.current,
            formats: ["bold", "italic", "underline", "strike", "list", "indent", "header", "link"],
            theme: 'snow',
        });

        quillInitialized.current = true;
        setQuillEditor(quill);

        // Set initial content
        quillSetHtml(quill, content);

        // Set edit mode, if there is no url in text and not disabled
        const editable = content.search(/(https?:|www.|<a)/) === -1 && !disabled;
        setEditMode(editable)
        quill.enable(editable);
        setEditMode(editable);
    }, [quillEditor, content, disabled]);

    useEffect(() => {
        if (!quillEditor) {
            return;
        }

        const handleTextChange = () => {
            onUpdate(quillGetHtml(quillEditor));
        };

        quillEditor.on("text-change", handleTextChange);
        return () => {quillEditor.off("text-change", handleTextChange)}
    }, [quillEditor, onUpdate]);

    const editButtonVisibility = isEditMode || disabled ? "none" : "visible";
    const formatButtonVisibility = isEditMode ? "visible" : "hidden";

    return (
        <div ref={boundsContainer}>
            <div ref={toolbarRef} style={{borderRadius: "4px", marginBottom: "6px"}}>
                <button
                    className="editor-btn"
                    style={{width: "unset", display: editButtonVisibility, color: "rgb(63, 81, 181)", fontWeight: "bold"}}
                    disabled={disabled}
                    onClick={() => {
                        if (quillEditor) {
                            setEditMode(true);
                            quillEditor.enable(true);
                        }
                    }}
                >
                    EDIT
                </button>

                <button className="ql-bold" style={{visibility: formatButtonVisibility}}></button>
                <button className="ql-italic" style={{visibility: formatButtonVisibility}}></button>
                <button className="ql-underline" style={{visibility: formatButtonVisibility}}></button>

                <button className="ql-list" value="ordered" style={{visibility: formatButtonVisibility}}></button>
                <button className="ql-list" value="bullet" style={{visibility: formatButtonVisibility}}></button>

                <button className="ql-link" style={{visibility: formatButtonVisibility}}></button>
                <button className="ql-clean" style={{visibility: formatButtonVisibility}}></button>
            </div>
            <div ref={editContainer} style={{height: 232, borderRadius: "4px", border: "1px solid rgb(204, 204, 204)"}}></div>
        </div>
    );
}
