import * as React from "react";
import {useMemo} from "react";
import * as Sentry from "@sentry/react";
import * as luxon from 'luxon';
import {
    createCustomTheme,
    Eventcalendar,
    luxonTimezone,
    MbscCalendarColor,
    MbscCalendarEvent,
    MbscCellClickEvent,
    MbscEventClickEvent,
    MbscEventCreatedEvent,
    MbscEventDragEvent,
    MbscEventUpdatedEvent,
    MbscEventUpdateEvent,
    MbscLocale,
    MbscPageLoadingEvent
} from "@mobiscroll/react";
import {print} from "@mobiscroll/print";
import {
    MbscEventcalendarView
} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public";
import {MbscScheduleEvent, MbscScheduleResource, sortResources} from "../utils/utils";
import {asLuxonDate, based0Weekday, compareStringDate, formatWorkDayHour, isInDayRange} from "../../../utils/date";
import {EventChangedPayload, EventChangedType} from "../utils/payload-types";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {EventTooltipProps} from "./event/event-tooltip";
import {ViewColumnWidths, ViewTimeframes} from "./toolbar/view-timeframe-selector";
import {CtxMenuEvent} from "./resource/resource-ctx-menu";

import "./mobiscroll/mobiscroll.custom.react.min.css";
import "./custom.css";
import {ErrorBoundary} from "../../../utils/error-boundary.";
import type {Scope} from "@sentry/core";

const MBSC_MODULES = [print];
createCustomTheme("teamcal", "material");

luxonTimezone.luxon = luxon;

export function TeamCalEventCalendar({
                                         viewTimeframe,
                                         selectedZoom,
                                         selectedDate,
                                         workWeekStart,
                                         workWeekEnd,
                                         workDayStart,
                                         workDayEnd,
                                         showWeekNumbers,
                                         showWorkHours,
                                         showWeekends,
                                         locale,
                                         inviteOnlyMode,
                                         resources,
                                         events,
                                         timezone,
                                         allowAdd,
                                         allowEdit,
                                         allowMove,
                                         renderHeader,
                                         renderResourceHeader,
                                         renderResource,
                                         renderSidebarHeader,
                                         renderSidebar,
                                         renderEvent,
                                         onViewChange,
                                         onEditEvent,
                                         onEventChanged,
                                         onCtxMenu,
                                         onTooltip,
                                     }: {
    viewTimeframe: string
    selectedZoom: string,
    selectedDate: string,
    workWeekStart: number,
    workWeekEnd: number,
    workDayStart: number,
    workDayEnd: number,
    showWeekNumbers: boolean,
    showWorkHours: boolean,
    showWeekends: boolean,
    locale: MbscLocale,
    inviteOnlyMode: boolean,
    resources: MbscScheduleResource[],
    events: MbscScheduleEvent[],
    timezone: string,
    allowAdd: boolean,
    allowEdit: boolean,
    allowMove: boolean,
    renderHeader?: any,
    renderResourceHeader?: any,
    renderSidebarHeader?: any,
    renderSidebar?: any
    renderResource?: any,
    renderEvent?: any,
    onViewChange: (viewStart: Date, viewEnd: Date, inst: EventcalendarBase) => Promise<void>,
    onEditEvent: (event: MbscScheduleEvent, resource: string) => Promise<void>,
    onEventChanged: (payload: EventChangedPayload) => Promise<unknown>,
    onCtxMenu: (args: CtxMenuEvent) => void,
    onTooltip: (show: boolean, args: EventTooltipProps) => void,
}) {
    const viewTimeframeSetting = ViewTimeframes.find(v => v.key === viewTimeframe)!

    const startTime = formatWorkDayHour(workDayStart);
    const endTime = formatWorkDayHour(workDayEnd);

    let startDay = locale.firstDay!;
    let endDay = (locale.firstDay! + 6) % 7;
    if (!showWeekends) {
        if (startDay < workWeekStart || startDay > workWeekEnd) {
            startDay = workWeekStart;
        }

        if (endDay > workWeekEnd || endDay < workWeekStart) {
            endDay = workWeekEnd;
        }
    }

    const viewOptions = {
        timeline: {
            type: viewTimeframeSetting.type,
            size: viewTimeframeSetting.size,
            columnWidth: ViewColumnWidths[selectedZoom],
            resolutionHorizontal: selectedZoom,
            startTime: showWorkHours ? startTime : "00:00",
            endTime: showWorkHours ? endTime : "24:00",
            timeCellStep: 60,
            timeLabelStep: 60,
            currentTimeIndicator: true,
            maxEventStack: "all",
            eventList: false,
            virtualScroll: true,
            weekNumbers: showWeekNumbers,
            startDay: startDay,
            endDay: endDay,
        }
    } as MbscEventcalendarView;

    const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    const weekendDays = days.filter((_, i) => !isInDayRange(i, workWeekStart, workWeekEnd));
    const viewColors = weekendDays.length > 0 ? [{
        background: "rgba(233, 233, 233, 0.46)",
        recurring: {
            repeat: "weekly",
            weekDays: weekendDays.join(","),
        },
    }] as MbscCalendarColor[] : [];

    if (selectedZoom === "week") {
        viewColors.push({
            cssClass: "tc-slot",
            recurring: {
                repeat: "daily",
            },
        });
    }

    if (viewOptions.timeline!.type === "day" && viewOptions.timeline!.size! > 1 && !showWeekends) {
        // If weekends are hidden, increase size to still show N size of days
        const startDay = based0Weekday(asLuxonDate(selectedDate));
        const neededWorkDays = viewOptions.timeline!.size!;
        let totalDays = 0;
        let foundWorkDays = 0;

        while (foundWorkDays < neededWorkDays) {
            if (isInDayRange((startDay + totalDays) % 7, workWeekStart, workWeekEnd)) {
                foundWorkDays++;
            }
            totalDays++;
        }

        viewOptions.timeline!.size = totalDays;
    }

    const handlePageLoading = async (args: MbscPageLoadingEvent, inst: EventcalendarBase) => {
        await onViewChange(args.viewStart, args.viewEnd, inst);
    };

    const handleNewEvent = () => {
        return {
            isNew: true,
            title: "",  // Overwrite MobileScroll default
            organizer: true,
            canInviteGuests: true,
            role: "W",
            outOfOffice: false,
        };
    }

    const handleCellRightClick = async (args: MbscCellClickEvent) => {
        args.domEvent.preventDefault();

        onCtxMenu({
            domEvent: args.domEvent as PointerEvent,
            date: args.date,
            resourceId: args.resource as string,
        });
    }

    const handleEventClick = async (args: MbscEventClickEvent) => {
        if (allowEdit && args.event.editable !== false) {
            await onEditEvent(args.event as MbscScheduleEvent, args.resource as string);
        }
    }

    const handleEventRightClick = async (args: MbscEventClickEvent) => {
        args.domEvent.preventDefault();

        if (args.event.editable === false) {
            return;
        }

        onCtxMenu({
            domEvent: args.domEvent as PointerEvent,
            date: args.date,
            event: args.event as MbscScheduleEvent,
            resourceId: args.resource as string,
        });
    }

    const handleEventCreated = async (args: MbscEventCreatedEvent) => {
        await onEditEvent(args.event as MbscScheduleEvent, args.resourceObj!.id as string);
    }

    const handleEventUpdate = (args: MbscEventUpdateEvent) => {
        if (inviteOnlyMode && args.oldResource !== args.resource) {
            void onEventChanged({
                type: EventChangedType.Invite,
                event: args.event as MbscScheduleEvent,
                oldEvent: args.oldEvent as MbscScheduleEvent,
                resourceId: args.resource as string,
                changedEventProperties: [],
                oldResourceId: args.oldResource as string,
            });

            return false;
        }
    }

    const handleEventUpdated = async (args: MbscEventUpdatedEvent) => {
        const changedEventProperties = new Set<string>();
        if (args.oldEvent && compareStringDate(args.oldEvent.start, args.event.start)) {
            changedEventProperties.add("start");
            changedEventProperties.add("allDay");
        }
        if (args.oldEvent && compareStringDate(args.oldEvent.end, args.event.end)) {
            changedEventProperties.add("end");
            changedEventProperties.add("allDay");
        }
        if (args.oldEvent && args.oldEvent.resource !== args.event.resource) {
            changedEventProperties.add("resource");
        }


        await onEventChanged({
            type: EventChangedType.Update,
            event: args.event as MbscScheduleEvent,
            oldEvent: args.oldEvent as MbscScheduleEvent,
            resourceId: args.resourceObj!.id as string,
            changedEventProperties: Array.from(changedEventProperties),
            oldResourceId: args.oldEvent ? args.oldEvent.resource as string : undefined,
        });
    }

    const triggerToolTipEvent = (show: boolean, args: MbscEventDragEvent | MbscEventClickEvent) => {
        if (args.event.more !== undefined || args.source === "popover") {
            // Do not trigger tooltip when showing the "more" event
            return;
        }

        onTooltip(show, {
            anchorEl: args.domEvent.target,
            event: args.event as MbscScheduleEvent,
            resource: args.resourceObj as MbscScheduleResource,
        });
    }

    const handleEventDragStart = (args: MbscEventDragEvent) => {
        triggerToolTipEvent(false, args);
    }

    const handleEventHoverIn = (args: MbscEventClickEvent) => {
        triggerToolTipEvent(true, args);
    }

    const handleEventHoverOut = (args: MbscEventClickEvent) => {
        triggerToolTipEvent(false, args);
    }

    const eventOrder = (event1: MbscCalendarEvent, event2: MbscCalendarEvent) => {
        // Order by:
        // 1. Work Locations
        // 2. All Day events
        // 3. Event start time
        // 4. Event title

        const byStartAndTitle = () => {
            const start1 = asLuxonDate(event1.start);
            const start2 = asLuxonDate(event2.start);
            if (start1.valueOf() <= start2.valueOf()) {
                return -1;
            } else if (start1.valueOf() > start2.valueOf()) {
                return 1;
            } else {
                return event1.title! > event2.title! ? 1 : -1;
            }
        }

        if (event1.workLocation && event2.workLocation) {
            return byStartAndTitle();
        } else if (event1.workLocation) {
            return -1;
        } else if (event2.workLocation) {
            return 1;
        } else if (event1.allDay && event2.allDay) {
            return byStartAndTitle();
        } else if (event1.allDay) {
            return -1;
        } else if (event2.allDay) {
            return 1;
        } else {
            return byStartAndTitle();
        }
    }

    const filteredResources = useMemo(() => {
        return resources.filter(resource => resource.visible).sort(sortResources)
    }, [resources]);

    return (
        <Sentry.ErrorBoundary
            beforeCapture={(scope: Scope) => {
                scope.setTag("tcSource", "Timeline Error Boundary");
            }}
            fallback={<ErrorBoundary/>}
        >
            <Eventcalendar
                locale={locale}
                modules={MBSC_MODULES}
                cssClass={`tc-zoom-${viewOptions.timeline?.resolutionHorizontal}`}
                theme="teamcal"
                themeVariant="light"
                timezonePlugin={luxonTimezone}
                dataTimezone="utc"
                displayTimezone={timezone}
                exclusiveEndDates={true}
                showControls={true}
                showEventTooltip={false}
                view={viewOptions}
                colors={viewColors}
                resources={filteredResources}
                data={events}
                clickToCreate={allowAdd}
                dragToCreate={allowAdd}
                dragToMove={true}
                dragInTime={allowEdit}
                dragToResize={allowEdit}
                dragBetweenResources={allowMove}
                refDate={selectedDate}
                selectedDate={selectedDate}
                extendDefaultEvent={handleNewEvent}
                eventOrder={eventOrder}
                onPageLoading={handlePageLoading}
                onCellRightClick={handleCellRightClick}
                onEventClick={handleEventClick}
                onEventRightClick={handleEventRightClick}
                onEventCreated={handleEventCreated}
                onEventUpdate={handleEventUpdate}
                onEventUpdated={handleEventUpdated}
                onEventDragStart={handleEventDragStart}
                onEventHoverIn={handleEventHoverIn}
                onEventHoverOut={handleEventHoverOut}
                renderHeader={renderHeader}
                renderResourceHeader={renderResourceHeader}
                renderResource={renderResource}
                renderSidebarHeader={renderSidebarHeader}
                renderSidebar={renderSidebar}
                renderScheduleEvent={renderEvent}
            />
        </Sentry.ErrorBoundary>
    );
}
