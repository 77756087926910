import * as React from "react";
import {ListItem, ListItemButton, ListItemText,} from "@mui/material";
import {ScheduleInfoWithActions} from "./schedules-view";
import {ScheduleActions} from "./schedule-actions";

export function ScheduleListItem({info}: { info: ScheduleInfoWithActions }) {
    return <ListItem
        key={info.id}
        divider={true}
        secondaryAction={
            <ScheduleActions info={info}/>
        }
    >
        <ListItemButton onClick={info.openSchedule}>
            <ListItemText primary={info.name} secondary={`${info.rowCount} calendars`}/>
        </ListItemButton>
    </ListItem>;
}
