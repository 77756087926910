import {useSchedule} from "../schedule/schedule-provider";
import {Box, Paper, Typography} from "@mui/material";
import {TeamCalEventCalendar} from "../schedule/event-calendar/teamcal-event-calendar";
import {EventTooltip, EventTooltipProps} from "../schedule/event-calendar/event/event-tooltip";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocale} from "../schedule/locale-provider";
import {MbscCalendarEventData} from "@mobiscroll/react";
import {
    buildFromScheduleRow,
    buildFromSharedScheduleEvent,
    MbscScheduleEvent,
    MbscScheduleResource,
    sortResources
} from "../schedule/utils/utils";
import {MemoRenderEvent} from "../schedule/event-calendar/event/render-event";
import {EventcalendarBase} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import {MemoCalendarToolbar} from "../schedule/event-calendar/toolbar/calendar-toolbar";
import {batchPromiseAll} from "../../utils/promise";
import {getSharedScheduleEvents} from "../../clients/teamcal-api";
import {useAuth} from "../../auth/auth-provider";
import {SharedAuthInfo} from "../../auth/shared-auth-provider";
import {applyEventFilter} from "../schedule/event-calendar/event/apply-event-filter";
import {useSearchParams} from "react-router";
import {
    parseBoolFromUrl,
    parseDateFromUrl,
    parseEventFilterFromUrl,
    parseModeFromUrl,
    parseViewScaleFromUrl
} from "./settings-parser";
import {mapLegacyViewScale, ViewTimeframes} from "../schedule/event-calendar/toolbar/view-timeframe-selector";
import {DateTime} from "luxon";
import {asLuxonDate} from "../../utils/date";

const BATCH_EVENT_API_SIZE = 10;
type CalendarView = {
    start: DateTime;
    end: DateTime;
}

export function SharedSchedulingView() {
    const {authContext, setAuthContext} = useAuth();
    const locale = useLocale();
    const {data, applyChanges} = useSchedule();
    const [searchParams] = useSearchParams();
    const [scheduleResources, setScheduleResources] = useState<MbscScheduleResource[]>(() => {
        return data.rows.map(buildFromScheduleRow).sort(sortResources);
    });
    const [scheduleEvents, setScheduleEvents] = useState<MbscScheduleEvent[]>([]);
    const [eventFilterByName, setEventFilterByName] = useState<string | undefined>(undefined);
    const [isRefreshingEvents, setIsRefreshingEvents] = useState<boolean>(true);
    const calendarInstanceRef = useRef<EventcalendarBase | undefined>(undefined);
    const calendarViewRef = useRef<CalendarView | undefined>(undefined);
    const [selectedDate, setSelectedDate] = useState<string>(() => {
        return parseDateFromUrl("date", searchParams).toISODate() as string
    });
    const [eventTooltip, setEventTooltip] = useState<EventTooltipProps | undefined>(undefined);
    const sharedAuthContext = authContext as SharedAuthInfo;

    /* Parse settings from the URL */
    useEffect(() => {
        let changes = [];

        const allDayUrlParam = parseBoolFromUrl('allday', null, searchParams);
        const outOfOfficeUrlParam = parseBoolFromUrl('outofoffice', null, searchParams);
        const viewScale = parseViewScaleFromUrl(searchParams);

        changes.push({prop: "viewMode", value: parseModeFromUrl(searchParams)});
        changes.push({prop: "showWeekends", value: parseBoolFromUrl("weekends", true, searchParams)});
        changes.push({prop: "showWorkHours", value: parseBoolFromUrl("workhours", true, searchParams)});
        changes.push({prop: "allDaySettings", value: allDayUrlParam ? 'only' : allDayUrlParam === false ? 'hide': null});
        changes.push({prop: "outOfOfficeSettings", value: outOfOfficeUrlParam ? 'only' : outOfOfficeUrlParam === false ? 'hide': null});

        if (viewScale) {
            const viewTimeframe = mapLegacyViewScale(viewScale).key;
            changes.push({prop: "viewTimeframe", value: viewTimeframe});
            if (viewScale.startsWith("WW")) {
                changes.push({prop: "showWeekends", value: false});
            }

            const ViewTimeframe = ViewTimeframes.find(v => v.key === viewTimeframe);
            if (ViewTimeframe) {
                changes.push({prop: "viewZoom", value: ViewTimeframe.defaultZoom});
            }
        }

        applyChanges(changes);

        const eventFilter = parseEventFilterFromUrl(searchParams);
        if (eventFilter) {
            setEventFilterByName(eventFilter);
        }

        setAuthContext(ctx => ({
            ...ctx,
            account: {
                ...ctx?.account!,
                userSettings: {
                    ...ctx?.account?.userSettings!,
                    showWeeks: parseBoolFromUrl("weeknumbers", false, searchParams) as boolean,
                }
            }
        }))
    }, [searchParams, applyChanges, setAuthContext]);

    const handleViewChange = async (viewStart: Date, viewEnd: Date, inst: EventcalendarBase) => {
        calendarInstanceRef.current = inst;
        calendarViewRef.current = {
            start: asLuxonDate(viewStart).setZone(locale.uiTimezone, {keepLocalTime: true}),
            end: asLuxonDate(viewEnd).setZone(locale.uiTimezone, {keepLocalTime: true}),
        };

        await loadScheduleEvents();
    };

    const loadScheduleEvents = useCallback(async () => {
        if (!calendarViewRef.current) {
            return;
        }

        setTimeout(() => {
            // Required to prevent React render error
            setIsRefreshingEvents(true);
        }, 0);

        const eventCalls = await batchPromiseAll(
            scheduleResources.flatMap(async row => {
                try {
                    return (await getSharedScheduleEvents(
                        sharedAuthContext.sharedToken!,
                        row.id as string,
                        calendarViewRef.current!.start.toJSDate(),
                        calendarViewRef.current!.end.toJSDate(),
                    )).map(event => {
                        row.roles.add(event.role);
                        return buildFromSharedScheduleEvent(event, row);
                    });
                } catch (error) {
                    return [];
                }
            })
            , BATCH_EVENT_API_SIZE);

        const events = eventCalls.flat();
        setScheduleEvents(events);
        setScheduleResources(resources => [...resources]);
        setIsRefreshingEvents(false);
    }, [scheduleResources, sharedAuthContext.sharedToken]);

    const handleTooltip = (show: boolean, args: EventTooltipProps) => {
        if (show && !eventTooltip) {
            setEventTooltip(args)
        } else {
            setEventTooltip(undefined);
        }
    }

    const renderHeader = () => {
        return (
            <MemoCalendarToolbar
                instance={calendarInstanceRef.current}
                isSharedMode={true}
                resources={scheduleResources}
                onAddCalendar={async () => {
                }}
                eventFilters={{
                    eventName: eventFilterByName,
                    allDayEvents: data.allDaySettings,
                    outOfOfficeEvents: data.outOfOfficeSettings,
                }}
                onEventFilterChange={(filterName: string, value?: string) => {
                    if (filterName === "eventName") {
                        setEventFilterByName(value);
                    } else {
                        applyChanges([{prop: filterName, value: value}]);
                    }
                }}
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
                isRefreshing={isRefreshingEvents}
                onRefresh={loadScheduleEvents}
                onEventClicked={() => {
                }}
                onJumpToEvent={() => {
                }}
            />
        );
    }

    const renderResource = useCallback((resource: MbscScheduleResource) => {
        return (
            <Box
                sx={{display: "flex", height: "100%", p: 1, overflow: "hidden"}}
                alignItems="center">
                <Typography noWrap textOverflow="ellipsis">{resource.name}</Typography>
            </Box>
        );
    }, []);

    const renderEvent = useCallback((args: MbscCalendarEventData) => {
        const event = args.original as MbscScheduleEvent;
        return (
            <MemoRenderEvent
                eventSize={data.viewMode}
                title={event.title}
                location={event.location}
                declinedEvent={event.declinedEvent}
                backgroundColor={args.color}
                workLocation={event.workLocation}
            />
        );
    }, [data.viewMode]);

    const filteredEvents = useMemo(() => applyEventFilter(
        scheduleEvents, data.allDaySettings, data.outOfOfficeSettings, eventFilterByName
    ), [
        scheduleEvents, data.allDaySettings, data.outOfOfficeSettings, eventFilterByName
    ])

    return (
        <Paper className="tc-scheduling-view" square elevation={1}>
            <TeamCalEventCalendar
                viewTimeframe={data.viewTimeframe!}
                selectedZoom={data.viewZoom!}
                selectedDate={selectedDate}
                workDayStart={data.workDayStart}
                workDayEnd={data.workDayEnd}
                workWeekStart={data.workWeekStart}
                workWeekEnd={data.workWeekEnd}
                showWeekNumbers={locale.showWeekNumbers}
                showWeekends={data.showWeekends}
                showWorkHours={data.showWorkHours}
                locale={locale.mbscLocale}
                inviteOnlyMode={data.moveAction === "I"}
                events={filteredEvents}
                resources={scheduleResources}
                timezone={locale.uiTimezone}
                allowAdd={false}
                allowEdit={false}
                allowMove={false}
                renderHeader={renderHeader}
                renderResourceHeader={undefined}
                renderResource={renderResource}
                renderEvent={renderEvent}
                onEventChanged={async () => {
                }}
                onViewChange={handleViewChange}
                onEditEvent={async () => {
                }}
                onCtxMenu={async () => {
                }}
                onTooltip={handleTooltip}
            />
            <EventTooltip
                event={eventTooltip?.event}
                resource={eventTooltip?.resource}
                anchorEl={eventTooltip?.anchorEl}
            />
        </Paper>
    );
}
